import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "antd";
import "../../../assets/css/_common.scss";
import "../../../assets/css/partials/events/events.scss";
import { useSelector, useDispatch } from "react-redux";
import AdminAddNew from "../../../common/adminAddNew";
import DropdownFilter from "./dropDownFilter";
import Listing from "./listing";
import { data } from "jquery";
import { API } from "../../../constants/api";
import * as ListAction from "../../../actions/experienceListingAction";
import Pagination from "@material-ui/lab/Pagination";
import AddExperiencesPackageDetails from "./addExperiencePackageDetails";
import { CSVLink } from "react-csv";
import ExperiencesPackageDetails from "./experiencePackageDetails";
import * as corpListingAction from "../../../actions/corpListingAction";
import ManageFeaturedPackage from "../../../common/manageFeaturedPackage";
import CsvIcon from "../../../assets/images/csvIcon.svg";
// import {getExperiencePackListDownload} from '../../../actions/experienceListingAction.js'
import * as experienceListingAction from "../../../actions/experienceListingAction";
import * as holidaysAction from "../../../actions/holidaysAction";
import { Spinner } from "react-bootstrap";

const ExperiencesListingIndex = () => {
  const [items, setItems] = useState([]);
  const [data, setData] = useState([]);
  const getCatId = useSelector(
    (state) => state?.experiencesListing?.event_category_id
  );
  const [catId, setCatId] = useState(getCatId);
  const search = useSelector((state) => state.experiencesListing.experiencelistSearchVal);

  const dispatch = useDispatch();

  const featuredList_data = useSelector(
    (state) => state.experiencesListing.featured
  );
  // const section_id = useSelector(
  //   (state) => state.experiencesListing.
  // )

  const dropDownvalue = useSelector(
    (state) => state.experiencesListing.experienceListingDropDownvalue
  );
 console.log(dropDownvalue?.name);
  const addExperiencePackage = useSelector(
    (state) => state.experiencesListing.addExperiencesPackage
  );
  const expList = useSelector(
    (state) => state.experiencesListing.experiencesList.data
  );
  const isDataLoading = useSelector(
    (state) => state.experiencesListing.isDataLoading
  );
  const isListingDataLoading = useSelector(
    (state) => state.experiencesListing.isListingDataLoading
  );
  console.log(expList);

  const pageID = useSelector((state) => state.experiencesListing.pageId);
  const [page, setPage] = useState(1);
  const experienceListingDropDownVal = useSelector(
    (state) => state.experiencesListing.experienceListingDropDownvalue
  );
  // const  { data } = state;
  useEffect(() => {
    dispatch(ListAction.getExperienceListing(page));
  }, []);

  useEffect(() => {
    if (expList && expList?.results?.length === 0) {
      dispatch(ListAction.handleAllImages([]));
    }
  }, [expList && expList?.results]);

  const downloadCSV = () => {
    const api = `${API.EXPERIENCE_CSV_DOWNLOAD}`
    dispatch(holidaysAction.downloadCSV(api, experienceListingDropDownVal?.id, experienceListingDropDownVal?.name))
  };
  useEffect(() => {
    return () => {
      dispatch(ListAction.getExperienceListing(page));
      dispatch(ListAction.pageId(page));
      dispatch(ListAction.experienceListingDropDownVal(null));
    };
  }, []);
  const handleResetPage = () => {
    setPage(1);
  };
  const handleEdit = (details) => {
    dispatch(ListAction.editExperienceListItem(details));
    dispatch(ListAction.setAddPackage(false));
  };
  const handleAdd = () => {
    dispatch(ListAction.setAddPackage(true));
    setPage(1);
    dispatch(ListAction.pageId(1));
  };
  const handlePagination = (e, value) => {
    setPage(value);
    if ( search !== ""){
      dispatch(
        ListAction.searchExperienceListing(
          search,
          experienceListingDropDownVal?.id,
          value
        )
      );
    } else {
    dispatch(ListAction.pageId(value));
    dispatch(
      ListAction.getExperienceListing(value, experienceListingDropDownVal?.id)
    );
    }
  };

  const totalListItems = expList?.total_results;
  const packageDetails = useSelector(
    (state) => state.experiencesListing.packageInfo
  );
  const [openManageFeaturePackage, setOpenManageFeaturePackage] = useState(
    false
  );
  const [
    showManageFeaturePackageButton,
    setShowManageFeaturePackageButton,
  ] = useState(false);
  const updateFeatureOrder = (list) => {
    dispatch(
      experienceListingAction.updateFeaturedList(
        list,
        experienceListingDropDownVal?.id
      )
    );
    console.log(experienceListingDropDownVal?.id);
  };
  // const [openDownloadModal, setOpendownloadModal] = useState(false);
  return (
    <div>
      <div style={{ position: "absolute", right: 50 }}>
        {/*<Datefilter />*/}
      </div>
      <div className="enquiry-tabs-holder">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="col-md-8" style={{ display: "flex", padding:"0px" }}>
            <h1 className="title-holder">
              {totalListItems} Experiences Listings
            </h1>
            <div>
              <DropdownFilter
                setShowManageFeaturePackageButton={
                  setShowManageFeaturePackageButton
                }
                disabled={isListingDataLoading}
              />
            </div>
          </div>
          <div
            type="button"
            value="CSV"
            className="col-md-4"
            style={{
              display: "flex",
              justifyContent: "center",
              // marginRight: "170px",
              // width: "668px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "160px",
                height: "42px",
                boxSizing: "border-box",
                alignItems: "center",
                position: "static",
              }}
            >
              {dropDownvalue?.name && (
                <div
                  style={{
                    border: "1px solid #C4C4C4",
                    display: "flex",
                    justifyContent: "center",
                    width: "138px",
                    height: "42px",
                    left: "0px",
                    border: "1px solid #C4C4C4",
                    boxSizing: "border-box",
                    borderRadius: "4px",
                    lineHeight: "36px",
                    alignItems: "center",
                    position: "static",
                    color: "#000000",
                    margin: "0px 9px",
                  }}
                  onClick={() => setOpenManageFeaturePackage(true)}
                >
                  <div
                    style={{
                      color: "#000000",
                      width: "112px",
                      height: "16px",
                      left: "13px",
                      paddingLeft: "3px",
                      paddingRight: "0px",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "400",
                      fontFamily: "DM Sans",
                      fontSize: "13px",
                      fontStyle: "normal",
                    }}
                  >
                    Manage Featured
                  </div>
                </div>
              )}
            </div>
            <div
              className="Input"
              style={{
                marginTop: "-7px",
                position: "static",
                flex: "none",
                order: "1",
              }}
            >
              <button
                className="btn btn-md btn-primary p-2"
                style={{ margin: 7 }}
                onClick={downloadCSV}
              >
                <img src={CsvIcon} /> Export to CSV
              </button>
            </div>
          </div>
        </div>
      </div>
      <Row>
        <Col
          style={{ paddingRight: "20px", marginRight: "-20px" }}
          className="gutter-row"
          xs={24}
          lg={8}
        >
          {/*<Col className="container"  xs={24} lg={8}>*/}
          <Row className="gutter-row ">
            <Listing
              experienceDetails={expList}
              handleEdit={handleEdit}
              handleAdd={handleAdd}
              currentPage={page}
              handleResetPage={handleResetPage}
            />
          </Row>
          {search&&isListingDataLoading?null:
          <Row style={{ paddingTop: "51%" }}>
            <Pagination
              count={expList?.total_pages}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={(event, value) => handlePagination(event, value)}
            />
          </Row>
            }
        </Col>
        <Col
          className="gutter-row"
          style={{ paddingRight: "10px", marginRight: "-20px" }}
          xs={24}
          lg={16}
        >
           {isDataLoading&&
              <div className="blured-backround">
                <p>
                  <Spinner animation="border" variant="primary" />
                </p>
              </div>
            }
          {addExperiencePackage ? (
            <div>
              <AddExperiencesPackageDetails />
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              {expList?.results && expList?.results.length > 0 ? (
                <ExperiencesPackageDetails
                  // onResetpage={handleResetPage}
                  data={
                    Object.keys(packageDetails).length === 0
                      ? expList?.results[0]
                      : packageDetails
                  }
                />
              ) : (
                <AddExperiencesPackageDetails />
              )}
            </div>
          )}
        </Col>
      </Row>
      {openManageFeaturePackage && (
        <ManageFeaturedPackage
          showModal={openManageFeaturePackage}
          featuredList_data={featuredList_data}
          dropDownvalue={dropDownvalue}
          listingAction={experienceListingAction}
          toggleFunc={setOpenManageFeaturePackage}
          updateOrder={updateFeatureOrder}
        />
      )}
    </div>
  );
};

export default ExperiencesListingIndex;
