import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import CorporatePackageDetails from "./CorporatePackageDetails";
import AddCorporatePackageDetails from "./AddCorporatePackageDetails";
import Pagination from "@material-ui/lab/Pagination";
import Listing from "./listing";
import DropdownFilter from "../bookings/DropdownFilter";
import * as ListAction from "../../../actions/corpListingAction";
import ManageFeaturedPackage from "../../../common/manageFeaturedPackage";
import * as corpListingAction from "../../../actions/corpListingAction";
import * as holidaysAction from "../../../actions/holidaysAction";
import CsvIcon from "../../../assets/images/csvIcon.svg";
import "../../../assets/css/_common.scss";
import "../../../assets/css/partials/events/events.scss";
import { API } from "../../../constants/api";
import { Spinner } from "react-bootstrap";

export default function Listings() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const featuredList_data = useSelector(
    (state) => state.corpListing.featured
  );
  const dropDownvalue = useSelector(
    (state) => state.corpListing.selectedDropdown
  );
  const isDataLoading = useSelector(
    (state) => state.corpListing.isDataLoading
  );
  const isListingDataLoading = useSelector(
    (state) => state.corpListing.isListingDataLoading
  );
  const corpList = useSelector((state) => state.corpListing.corporateList.data);
  const addCorpPackage = useSelector(
    (state) => state.corpListing.addCorporatePackage
  );
  const pageId = useSelector((state) => state.corpListing.corpPageId);
  const selectedMenuDropdown = useSelector(
    (state) => state.corpListing?.selectedDropdown
  );
  const dropDownFilterSecId = useSelector(
    (state) => state.corpListing?.corpDropDownFilteredSecId
  );
  console.log(dropDownFilterSecId);
 console.log(dropDownFilterSecId?.name, "hhhh");
 const [
  showManageFeaturePackageButton,
  setShowManageFeaturePackageButton,
] = useState(false);
  useEffect(() => {

    console.log(dropDownvalue);
  },[dropDownvalue])
  const totalListItems = corpList?.total_results;
  const packageDetails = useSelector((state) => state.corpListing.packageInfo);
  const corpListSearchValue = useSelector(
    (state) => state.corpListing?.corpListSearchValue
  );
  useEffect(() => {
    dispatch(ListAction.handleAllImages([]));
  }, [corpList && corpList?.results?.length === 0]);

  useEffect(() => {
    dispatch(ListAction.getCorpListing(page, null));
  }, []);
  useEffect(() => {
    return(() => {
      dispatch(corpListingAction.corpDropDownFilterSecId(null))
    })
  },[])

  useEffect(() => {
    if (
      corpList &&
      corpList?.results?.length === 0 &&
      corpList &&
      corpList?.current_page > 1 &&
      corpList &&
      corpList?.next_page_id === null
    ) {
      dispatch(ListAction.getCorpListing(1, dropDownFilterSecId));
      dispatch(ListAction.corpPageId(1));
    }
  }, [corpList && corpList?.results]);

  const handleEdit = (details) => {
    dispatch(ListAction.editCorpListItem(details));
    dispatch(ListAction.setAddPackage(false));
  };
  
  const downloadCSV = () => {
    const api = `${API.CORPORATE_CSV_DOWNLOAD}`
    dispatch(holidaysAction.downloadCSV(api, dropDownFilterSecId?.id, dropDownFilterSecId?.name))
  };
  const handleAdd = () => {
    dispatch(ListAction.setAddPackage(true));
  };
  const handleResetPage = () => {
    setPage(1);
  };
  const handlePagination = (e, value) => {
    setPage(value);
    if (corpListSearchValue !== "") {
      dispatch(ListAction.searchListing(corpListSearchValue, value));
    } else {
      dispatch(ListAction.getCorpListing(value, dropDownFilterSecId?.id));
      dispatch(ListAction.corpPageId(value));
    }
  };

  // const totalListItems = corpList?.total_results;
  // const packageDetails = useSelector((state) => state.corpListing.packageInfo);

  const [openManageFeaturePackage, setOpenManageFeaturePackage] = useState(
    false
  );
  ;
  const updateFeatureOrder = (list) => {
    dispatch(ListAction.updateFeaturedList(list,dropDownFilterSecId?.id ));
    console.log(dropDownFilterSecId?.id, "ddd")
  };
  
  return (
    <div>
      <div style={{ position: "absolute", right: 50 }}></div>
      <div className="enquiry-tabs-holder">
      <div style={{ display: "flex", justifyContent: "space-between" }}>

        <div className="col-md-8" style={{ display: "flex", padding:"0px" }}>
          <h1 className="title-holder">{totalListItems} Corporates Listings</h1>
          <div>
            <DropdownFilter 
            setShowManageFeaturePackageButton={
              setShowManageFeaturePackageButton
            }
            disabled={isListingDataLoading}
            status={"filteredListingArray"} />
          </div>
        </div>
        <div
        type="button"
        value="CSV"
          className="col-md-4"
          style={{
            display: "flex",
            justifyContent: "center",
            // marginRight: "170px",
            // width: "668px",
          }}
        >
          <div 
           style={{
            display: "flex",
            justifyContent: "center",
            width: "160px",
            height: "42px",
            boxSizing: "border-box",
            alignItems: "center",
            position: "static",
          }}
          >
            {dropDownFilterSecId?.name && (
          <div
            type="button"
            style={{
              border: "1px solid #C4C4C4",
              display: "flex",
              justifyContent: "center",
              width: "138px",
                height: "42px",
              left: "0px",
              border: "1px solid #C4C4C4",
              boxSizing: "border-box",
              borderRadius: "4px",
              lineHeight: "36px",
              alignItems: "center",
              position: "static",
              color: "#000000",
              margin: "0px 9px",
            }}
            onClick={() => setOpenManageFeaturePackage(true)}
          >
            <div
              style={{
                color: "#000000",
                width: "112px",
                height: "16px",
                lineHeight:"36px",
                left: "13px",
                paddingLeft: "3px",
                paddingRight: "0px",
                display: "flex",
                alignItems: "center",
                fontWeight: "400",
                fontFamily: "DM Sans",
                fontSize: "13px",
                fontStyle: "normal",
              }}
            >
              Manage Featured
            </div>
          </div>
          )}
          </div>
          <div className="Input" style={{ marginTop: "-7px" }}>
              <button
                className="btn btn-md btn-primary p-2"
                style={{ margin: 7 }}
                onClick={downloadCSV}
              >
                <img src={CsvIcon} /> Export to CSV
              </button>
            </div>
        </div>
      </div>
      </div>
      <Row>
        <Col
          style={{ paddingRight: "20px", marginRight: "-20px" }}
          className="gutter-row"
          xs={24}
          lg={8}
        >
          {/*<Col className="container"  xs={24} lg={8}>*/}
          <Row className="gutter-row ">
            <Listing
              corporateDetails={corpList}
              handleEdit={handleEdit}
              handleAdd={handleAdd}
              currentPage={page}
              handleResetPage={handleResetPage}
            />
          </Row>
          <Row style={{ paddingTop: `${addCorpPackage ? "62%" : "50%"}` }}>
            <Pagination
              count={corpList?.total_pages}
              variant="outlined"
              shape="rounded"
              page={page}
              // currentPage={page}
              onChange={(event, value) => handlePagination(event, value)}
            />
          </Row>
        </Col>
        <Col className="gutter-row" xs={24} lg={16}>
        {isDataLoading&&
              <div className="blured-backround">
                <p>
                  <Spinner animation="border" variant="primary" />
                </p>
              </div>
            }
          {addCorpPackage ? (
            // <div>
            <AddCorporatePackageDetails />
          ) : (
            // </div>
            <div>
              {corpList?.results && corpList?.results.length > 0 ? (
                <CorporatePackageDetails
                  data={
                    Object.keys(packageDetails).length === 0
                    ? corpList?.results[0]
                    : packageDetails
                  }
                  page={page}
                />
              ) : (
                <AddCorporatePackageDetails />
              )}
            </div>
          )}
        </Col>
      </Row>
      {openManageFeaturePackage && (
        <ManageFeaturedPackage
          showModal={openManageFeaturePackage}
          featuredList_data={featuredList_data}
          dropDownvalue={dropDownFilterSecId}
          listingAction={corpListingAction}
          toggleFunc={setOpenManageFeaturePackage}
          updateOrder={updateFeatureOrder}
        />
      )}
    </div>
  );
}
