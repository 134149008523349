import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import PinLocation from "../../../assets/images/pin.svg";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSelector, useDispatch } from "react-redux";
import Edit from "../../../assets/images/edit.svg";
import GoogleMapModal from "../../utilities/GoogleMap/MapModal";
import ArrowDown from "../../../assets/images/arrowDown.svg";
import ArrowWhite from "../../../assets/images/arrowWhite.png";
import { makeStyles } from "@material-ui/core/styles";
import "../../../assets/css/partials/packageDetails.scss";
import { FaFileUpload } from "react-icons/fa";
import JoditEditor from "jodit-react";
import PlusIcon from "../../../assets/images/plusIconSquare.svg";
// import ImageMultiSelect from "../listings/imageMultiSelect/imageMultiSelect";
import ImageMultiSelect from "../../../common/imageMultiSelect/imageMultiSelect";
import Switch from "@material-ui/core/Switch";
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Popup from "../../popup/popup";
import * as ListAction from "../../../actions/corpListingAction";
import { useSnackbar } from "notistack";
import * as holidayListingAction from "../../../actions/holidaysAction";
import * as bookingAction from "../../../actions/bookingAction";
import { scheduleDaysVal, focusInput, scheduleNightsVal, dateFormat, checkFeaturedAmenitiesLength, getActivityTypeVal, isNewPack, } from '../../../utils/index';
import DefaultImage from "../../../assets/images/plain-background.jpeg";
import ToggleButton from '../../holidays/listings/toggle';
import OpeningSoonCalander from "./openingSoonCalander";
import moment from 'moment';
import Autocomplete from "../../../common/autoComplete";
import LinearProgress from '@material-ui/core/LinearProgress';
import { countryList } from '../../../common/countryList';
import Testimonial from "../../../common/testimonial";
import Reviews from "../../../common/reviews";
import FeaturedPackage from "../../../common/featuredPackage";
import MoveToCorporate from "../../../common/moveToCorporate";
import Input from "../../../common/uiElements/input";
import PackTypeButtons from "../../../common/uiElements/packTypeButtons";
import ToggleGroup from "../../../common/uiElements/toggelGroup";
import Error from "../../../common/error";
import MinMaxInput from "../../../common/uiElements/minMaxInput";
import Dropdown from "../../../common/uiElements/dropdown";
import ScheduleBoxItinerary from "./scheduleBoxItinerary";
import BlockDatePopup from "../../popup/blockDatePopup";
import AddHandleRate from "../../../common/addRateModel";
import { Tabs } from "antd";
import {
  ACCOMODATION_EMPTY,
  ACTIVITY_LEVEL_EMPTY,
  ACTIVITY_TYPE_EMPTY,
  AGES_EMPTY,
  CITY_ERROR,
  COUNTRY_ERROR,
  ESSENTIAL_INFO_EMPTY,
  FAQ,
  FEATURED_AMENITIES_ERROR,
  GUEST_EMPTY,
  GUEST_ERROR,
  ITINERARY_EMPTY,
  LOCATION_TYPE_CHAR_LIMIT,
  LOCATION_TYPE_EMPTY,
  PRICE,
  PRICE_LENGTH,
  REVISED_PRICE,
  REVISED_PRICE_ERROR,
  REVISED_PRICE_LENGTH,
} from '../../../utils/constants';
import { FEATURED_AMENITIES_LIST, ROOM_AMENITIES_LIST, ACTIVITY_OPTIONS, ACTIVITY_LEVEL_OPTIONS, RESORT_FACILITIES_LIST } from '../../../resources/packageData'
import SecondLocation from "../../../common/secondLocation";
import CkEditor from "../../../common/ckEditor/ckeditor";
import Meta_Input from "../../../common/uiElements/meta_Input";

const PurpleSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: '#2196F3',
    },
    '&$checked + $track': {
      backgroundColor: '#2196F3',
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  selectedPackage: {
    position: "relative",
    cursor: "pointer",
    boxShadow: "3px 2px 5px #666"
  },
  activePoints: {
    backgroundColor: "rgba(40,167,69,0.5)",
    borderRadius: '5px',
  },
  inActivePoints: {
    borderRadius: '5px',
    backgroundColor: "rgb(244, 155, 155)"
  },
  package: {
    position: "relative",
    cursor: "pointer"
  },
  selectedPoints: {
    paddingTop: "7px",
    paddingBottom: "7px",
    paddingLeft: "16px",
    paddingRight: "49px",
    fontSize: "17px",
    color: '#000',
    width: '141px',
    marginBottom: "0px"
  },
  points: {
    paddingTop: "7px",
    paddingBottom: "7px",
    paddingLeft: "16px",
    paddingRight: "49px",
    fontSize: "16px",
    color: '#333',
    width: '141px',
    marginBottom: "0px"
  },
}));
const { TabPane } = Tabs;

// let resData = null;

export default function EditHolidayPackageDetails({ data, onResetpage }) {
  const classes = useStyles();
  const [currentPackage, setCurrentPackage] = useState(data?.packages[0] !== undefined ? data?.packages[0] : []);
  console.log(data?.packages, "ghl");
  console.log(currentPackage, "ghjkl");
  const [imageSelect, setImageSelect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let resData = currentPackage?.schedule_data;
  const { enqueueSnackbar } = useSnackbar();
  const PACKAGE_TYPE = data?.packages?.[0];

const[block_date_range,setBlock_date_range]=useState(currentPackage?.restricted_booking_dates?.selected_dates)
console.log(block_date_range,"block_date_range");
  const [scheduleTypeUpdateBool, setScheduleTypeUpdateBool] = useState(false);
  const[addHandleRate,setAddHandleRate]=useState(false)
  const [popupSelect, setPopupSelect] = useState(false);
  const [popupDateBlock,setPopupDateBLock]=useState(false)
  const [blockDates_Range,setBlockDates_range]=useState()
  const [packageDropdown, setPackageDropdown] = useState(null);
  const [packageId, setPackageId] = useState(null);
  const [isOnchange, setIsOnchange] = useState(false);
  const [locationSelect, setLocationSelect] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isGiftVoucher, setIsGiftVoucher] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isAddAnotherPackage, setIsAddAnotherPackage] = useState(false)
  const listingData = useSelector((state) => state.corpListing.listDataPost);
  const [dropdownValue, setDropdownValue] = useState("Select section");
  const [sectionId, setSectionId] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [select, setSelect] = React.useState(null);
  const [symbolValidation,setSymbolValidation]=useState(false);
  const[symbolErrorMsg,setSymbolErrorMsg]=useState()
  const [isSave, setIsSave] = useState(false);

  const [isTitleEmptyErrorValidation, setIsTitleEmptyErrorValidationn] = useState(null);
  const [isTitleLengthExceedErrorValidation, setIsTitleLengthExceedErrorValidation] = useState(null);

  const [isPackageEmptyErrorValidation, setIsPackageEmptyErrorValidation] = useState(null);
  const [ispackTitleErrorValidation, setIspackTitleErrorValidation] = useState(null);
  const [isLocationErrorValidation, setIsLocationErrorValidation] = useState(null);
  const [isFeaturedErrorValidation, setIsFeaturedErrorValidation] = useState("");
  const [isValidTillErrorValidation, setIsValidTillErrorValidation] = useState(null);
  const [isScheduleErrorValidation, setIsScheduleErrorValidation] = useState(null);
  const [isBriefDescriptionErrorValidation, setIsBriefDescriptionErrorValidation] = useState(null);
  const [isisBriefDescriptionLengthErrorValidation, setIsBriefDescriptionLengthErrorValidation] = useState(null);
  const [isDescriptionErrorValidation, setIsDescriptionErrorValidation] = useState(null);
  const [isImageErrorValidation, setIsImageErrorValidation] = useState(null);
  const [isImageThumbnailErrorValidation, setIsImageThumbnailErrorValidation] = useState(null);
  const [isPointsErrorValidation, setIsPointsErrorValidation] = useState(null);
  const [isPointsLengthErrorValidation, setIsPointsLengthErrorValidation] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [isError, setIsError] = useState({ error: false, msg: "", name: "" })
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [disable, setDisable] = useState(false);
  const [openingSoon, setOpeningSoon] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [dateValueBool, setDateValueBool] = useState(false);
  const [scheduleType, setScheduleType] = useState(currentPackage?.schedule_type);
  const [openingSoonDateValue, setOpeningSoonDateValue] = useState(currentPackage?.opening_soon);
  const [dateRangeDate, setDateRangeDate] = useState(null);
  const [isEmptyResultsArrayBool, setIsEmptyResultsArrayBool] = useState(false);
  const [updateBtnDisable, setUpdateBtnDisable] = useState(false);
  const [packageType, setPackageType] = useState(null);

  const [locationCityvalue, setLocationCityvalue] = useState("");
  const [value, setValue] = useState(currentPackage?.location_city);
  const [deletePackItem, setDeletePackItem] = useState(false);
  const holidayList = useSelector((state) => state.holidayListing.holidayList.data);
  const[toDeleteIds,setToDeleteIds]=useState([])

  
  const [metaInfo, setMetaInfo] = useState({
    "meta_title": "",
    "meta_description": ""
  })
  const addImage = useSelector((state) => state.holidayListing.allImagesHolidayData);
  const holidayListingResults = useSelector((state) => state.holidayListing.holidayList);
  const holidayListingDropDownVal = useSelector((state) => state.holidayListing.holidayListingDropDownvalue);
  const packageDetails = useSelector((state) => state.holidayListing.packageInfoHoliday);
  const openingSoonVal = useSelector((state) => state.holidayListing.openingSoonValue);

  const selectedMenuDropdown = useSelector((state) => state.holidayListing?.selectedHolidayDropdown);
  const pageID = useSelector((state) => state.holidayListing.pageId);
  const getLocationdropDownList = useSelector((state) => state.holidayListing.dropDownLocationlist);
  const search = useSelector((state) => state.holidayListing.holidaylistSearchVal);

  const editor = useRef(null);
  const [content, setContent] = useState(currentPackage?.description);
  // const [priceList, setPriceList] = useState({
  //   adults: {
  //     adult1: null,
  //     adult2: null,
  //     adult3: null,
  //     adult4: null,
  //     adult5: null,
  //     adult6: null,
  //     adult7: null,
  //     adult8: null,
  //     adult9: null,
  //     adult10: null,
  //   },
  //   children: {
  //     child1: null,
  //     child2: null,
  //     child3: null,
  //     child4: null,
  //     child5: null,
  //     child6: null,
  //     child7: null,
  //     child8: null,
  //     child9: null,
  //     child10: null,
  //   },
  // });
  const [priceList, setPriceList] = useState({
    adults: [{adult1:null}],
    children: [{child1:null}]
  });


const handleAddPrice=()=>{

  const allValuesUpdated = Object.values(priceList).every(categoryArray =>
    categoryArray.every(item =>
      Object.values(item).every(value => {
        const isValid = value !== null && !isNaN(value);
        return isValid;
      })
    )
  );
  
    if (!allValuesUpdated) {
          enqueueSnackbar("Please fill all the fields", {
            variant: "error",
          });
        } else {
          
          setAddHandleRate(false);
        }
  
}

  const apiGiftVoucher = data?.section?.gift_voucher;
  const currentImages = addImage || [];
  const totalImages = addImage.filter((o) => currentImages.indexOf(o) === -1);
  const setThumbnail = addImage.find((img) => img.thumbnail === true);
  const imageInPopup = setThumbnail?.image;
  let resultedLists = selectedMenuDropdown && selectedMenuDropdown.
    filter(item => item.name !== "Visas & More" && item.name !== "Customize" && item.name !== "Send a Gift" && item.name !== "International");

  let CityAndCountryLabel = `${data?.section?.name === "International" ? "Select Country" : "City of Location *"} `

  useEffect(() => {
    if (packageDetails) {
      setIsSave(false);
      setIsAddAnotherPackage(false)
      setDisable(false)
    };
    setIsPackageEmptyErrorValidation(false);
    setIspackTitleErrorValidation(false);
    setIsLocationErrorValidation(false);
    setIsScheduleErrorValidation(false);
    setIsValidTillErrorValidation(false);
    setIsBriefDescriptionErrorValidation(false);
    setIsDescriptionErrorValidation(false);
    setIsBriefDescriptionLengthErrorValidation(false)
    setIsPointsErrorValidation(false);
    setIsPointsLengthErrorValidation(false);
    setIsImageErrorValidation(false);
    setIsImageThumbnailErrorValidation(false);
    setIsTitleEmptyErrorValidationn(false);
    setIsTitleLengthExceedErrorValidation(false);
    setCountryError(false)
    setIsError({ error: false, msg: "", name: "" })
  }, [packageDetails]);

  useEffect(() => {
    if (currentPackage.opening_soon)
      dispatch(holidayListingAction.handleOpeningSoonDate(currentPackage.opening_soon));

  }, []);;

  useEffect(() => {
    dispatch(holidayListingAction.getDropDownLocationList());
  }, [value]);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevData = usePrevious(data?.packages);

  useEffect(() => {
    if (data?.packages.length !== 0 && (prevData?.length !== data?.packages?.length || prevData?.length === data?.packages?.length)) {
      setSelectedPackage(data?.packages[0].id);
    }
  }, [data?.packages]);

  useEffect(() => {
    dispatch(holidayListingAction.handleAllImages(currentPackage?.images || []));
    if (isUpdateData) {
      dispatch(holidayListingAction.handleAllImages(currentImages));
    }
    setContent(currentPackage?.description);
  }, [currentPackage]);
  useEffect(() => {
    if (currentPackage?.meta_info !== null) {
      setMetaInfo(currentPackage?.meta_info)
    } else {
      setMetaInfo({
        "meta_title": "",
        "meta_description": ""
      })
    }
  }, [currentPackage]);
  useEffect(() => {
    setCurrentPackage(() => ({
      ...currentPackage,
      meta_info: metaInfo,
    }));
  }, [metaInfo]);
  useEffect(() => {
    setCurrentPackage(() => ({
      ...currentPackage,
      description: content,
    }));
  }, [content]);

  // const priceDataLoad=()=>{
  //   if (currentPackage) {

  //     const adultPrices = currentPackage.price_list?.adult_prices || [];
  //     const childPrices = currentPackage.price_list?.child_prices || [];
  //     console.log(currentPackage.price_list?.adult_prices);
  
     
  //     setPriceList((prevPriceList) => ({
  //       ...prevPriceList,
  //       adults: Object.fromEntries(
  //         Object.keys(prevPriceList.adults).map((key, index) => [
  //           key,
  //           adultPrices[index]?.price?adultPrices[index]?.price : adultPrices[index]?.price === 0 ? 0 :index === 0  && currentPackage?.revised_price>0? currentPackage?.revised_price:null
  //         ])
  //       )
  //     }));
      
  
   
  //     setPriceList((prevPriceList) => ({
  //       ...prevPriceList,
  //       children: Object.fromEntries(
  //         Object.keys(prevPriceList.children).map((key, index) => [
  //           key,
  //           childPrices[index]?.price ?childPrices[index]?.price : childPrices[index]?.price === 0 ? 0 : null
           
  //         ])
  //       ),
  //     }));
  //   }

  // }
  const priceDataLoad = () => {
    if (currentPackage) {
      const adultPrices = currentPackage.price_list?.adult_prices || [];
      const childPrices = currentPackage.price_list?.child_prices || [];
      const revised_price=currentPackage.revised_price?currentPackage.revised_price:null

  
      setPriceList((prevPriceList) => ({
        ...prevPriceList,
        adults:currentPackage.price_list?.adult_prices.length<=0?[{"adult1":revised_price}]: adultPrices.map((elem, index) => ({
          [`adult${index + 1}`]: elem.price !== undefined
            ? elem.price
            : index === 0 && currentPackage?.revised_price > 0
            ? currentPackage.revised_price
            : null,
            "id":elem.id

        }))
      }));
  
      setPriceList((prevPriceList) => ({
        ...prevPriceList,
        children:currentPackage.price_list?.adult_prices.length<=0?[{"child1":null}]: childPrices.map((elem, index) => ({
          [`child${index + 1}`]: elem.price !== undefined ? elem.price : null,
          "id":elem.id
        }))
      }));
    }
  };
  
  

  useEffect(()=>{
    setBlock_date_range(currentPackage?.restricted_booking_dates?.selected_dates)
    
   

  },[currentPackage])

  useEffect(()=>{
    priceDataLoad()

  },[currentPackage?.price_list?.adult_prices[0]?.price,selectedPackage])
 

  useEffect(()=>{
    if(block_date_range){
      const data=block_date_range[0]?block_date_range[0]:false
      const data1=block_date_range[1]?block_date_range[1]:false
      setBlockDates_range(`${data ? `${data.from} to ${data.to}` : ''}${data1 ? `,${data1.from}` : ''}`);

    }




  },[block_date_range])

  useEffect(() => {
    if (deletePackItem) {
      let id = holidayList.results[0].id
      dispatch(holidayListingAction.getHolidayPackageInfo(id));
    }
  }, [deletePackItem]);

  //map all data similarly and use
  const [state, setState] = useState({
    section: null,
    package_type: null,
    corp_id: null,
    pack_id: null,
    restricted_booking_dates:null,
    title: "",
    pack_title: "",
    meta_title: "",
    meta_description: "",
    edenred_product_code:"",
    brief_description: "",
    note: null,
    images: [],
    location: [],
    packages: [],
    schedule_type: "",
    schedule_data: {},
    valid_till: "",
    price: null,
    best_seller: false,
    description: "",
    covid_safe: false,
    location_city: "",
    location_country: "",
    featured: false,
    deal: false,
    opening_soon: null,
    flexi_schedule: false,
    enable_payment: false,
    revised_price: "",
    featured_amenities: JSON.parse(JSON.stringify(FEATURED_AMENITIES_LIST)),
    room_amenities: JSON.parse(JSON.stringify(ROOM_AMENITIES_LIST)),
    hotel_resort_facilities: JSON.parse(JSON.stringify(RESORT_FACILITIES_LIST)),
    television: false,
    location_type: "",
    activity_type: "",
    activity_level: "",
    ages: "",
    group_size: { min: "", max: "" },
    itinerary: "",
    accomodations: "",
    essential_info: "",
    faq: "",
    price_list:[]
  });

  console.log(state, "stste");

  useEffect(() => {
    if (holidayListingResults.data.results.length === 0) {
      setIsEmptyResultsArrayBool(true);
      setCurrentPackage({
        location_city: ""
      })
    } else {
      setIsEmptyResultsArrayBool(false);
    }
  }, [holidayListingResults]);

  useEffect(() => {
    setState({
      ...state,
      section: data?.section?.name || undefined,
      package_type: currentPackage?.package_type,
      corp_id: data?.id || null,
      pack_id: currentPackage?.id || null,
      title: data?.title.trim().replace(/\s+/g, ' ') || "",
      pack_title: currentPackage?.pack_title.trim().replace(/\s+/g, ' ') || "",
      meta_info: JSON.parse(JSON.stringify(metaInfo)),
      edenred_product_code:currentPackage?.edenred_product_code,
      brief_description: currentPackage?.brief_description || "",
      note: currentPackage?.note || null,
      images: currentPackage?.images || currentImages,
      location: currentPackage?.location || [],
      packages: data?.packages || [],
      schedule_type: currentPackage?.schedule_type,
      schedule_data: currentPackage?.schedule_data || {},
      valid_till: currentPackage?.valid_till || "",
      price: currentPackage?.price || null,
      best_seller: currentPackage?.best_seller,
      description: currentPackage?.description || "",
      covid_safe: currentPackage.covid_safe,
      deal: currentPackage.deal,
      opening_soon: currentPackage.opening_soon,
      flexi_schedule: currentPackage.flexi_schedule,
      featured: data ? data.featured : false,
      enable_payment: currentPackage?.enable_payment,
      location_city: currentPackage.location_city || '',
      location_country: currentPackage.location_country || '',
      revised_price: currentPackage?.price_list?.adult_prices?.length>0?priceList?.adults?.adult1:currentPackage?.revised_price,
      featured_amenities: JSON.parse(JSON.stringify(FEATURED_AMENITIES_LIST)),
      room_amenities: JSON.parse(JSON.stringify(ROOM_AMENITIES_LIST)),
      hotel_resort_facilities: JSON.parse(JSON.stringify(RESORT_FACILITIES_LIST)),
      location_type: currentPackage?.location_type,
      activity_type: currentPackage?.activity_type,
      activity_level: currentPackage?.activity_level,
      ages: currentPackage?.ages,
      group_size: currentPackage?.group_size,
      itinerary: currentPackage.itinerary,
      accomodations: currentPackage.accomodations,
      essential_info: currentPackage.essential_info,
      faq: currentPackage.faq,

      price_list:currentPackage?.price_list?.adult_prices?.length>0 && !isSave?[
        ...currentPackage?.price_list?.adult_prices?.map((elem,index)=>{
          return {"id": elem.id,"age_group":"adult", "num_people":(index + 1),"price":elem['adult' + (index + 1)]}
        }),
        ...currentPackage?.price_list?.child_prices?.map((elem,index)=>{
          return {"id": elem.id,"age_group":"child", "num_people":(index + 1),"price":elem['child' + (index + 1)]}

        })
   
        // {"id":currentPackage?.price_list?.adult_prices[0].id,"age_group":"adult", "num_people":1, "price": parseInt(priceList?.adults?.adult1)}, {"id":currentPackage?.price_list?.adult_prices[1].id,"age_group":"adult", "num_people":2, "price": parseInt(priceList?.adults?.adult2)},
        // {"id":currentPackage?.price_list?.adult_prices[2].id,"age_group":"adult", "num_people":3, "price": parseInt(priceList?.adults?.adult3)}, {"id":currentPackage?.price_list?.adult_prices[3].id,"age_group":"adult", "num_people":4, "price": parseInt(priceList?.adults?.adult4)},
        // {"id":currentPackage?.price_list?.adult_prices[4].id,"age_group":"adult", "num_people":5, "price": parseInt(priceList?.adults?.adult5)}, {"id":currentPackage?.price_list?.adult_prices[5].id,"age_group":"adult", "num_people":6, "price": parseInt(priceList?.adults?.adult6)},
        // {"id":currentPackage?.price_list?.adult_prices[6].id,"age_group":"adult", "num_people":7, "price": parseInt(priceList?.adults?.adult7)}, {"id":currentPackage?.price_list?.adult_prices[7].id,"age_group":"adult", "num_people":8, "price": parseInt(priceList?.adults?.adult8)},
        // {"id":currentPackage?.price_list?.adult_prices[8].id,"age_group":"adult", "num_people":9, "price": parseInt(priceList?.adults?.adult9)}, {"id":currentPackage?.price_list?.adult_prices[9].id,"age_group":"adult", "num_people":10, "price": parseInt(priceList?.adults?.adult10)},
        // {"id":currentPackage?.price_list?.child_prices[0].id,"age_group":"child", "num_people":1, "price": parseInt(priceList?.children?.child1)}, {"id":currentPackage?.price_list?.child_prices[1].id,"age_group":"child", "num_people":2, "price": parseInt(priceList?.children?.child2)},
        // {"id":currentPackage?.price_list?.child_prices[2].id,"age_group":"child", "num_people":3, "price": parseInt(priceList?.children?.child3)}, {"id":currentPackage?.price_list?.child_prices[3].id,"age_group":"child", "num_people":4, "price": parseInt(priceList?.children?.child4)},
        // {"id":currentPackage?.price_list?.child_prices[4].id,"age_group":"child", "num_people":5, "price": parseInt(priceList?.children?.child5)}, {"id":currentPackage?.price_list?.child_prices[5].id,"age_group":"child", "num_people":6, "price": parseInt(priceList?.children?.child6)},
        // {"id":currentPackage?.price_list?.child_prices[6].id,"age_group":"child", "num_people":7, "price": parseInt(priceList?.children?.child7)}, {"id":currentPackage?.price_list?.child_prices[7].id,"age_group":"child", "num_people":8, "price": parseInt(priceList?.children?.child8)},
        // {"id":currentPackage?.price_list?.child_prices[8].id,"age_group":"child", "num_people":9, "price": parseInt(priceList?.children?.child9)}, {"id":currentPackage?.price_list?.child_prices[9].id,"age_group":"child", "num_people":10, "price": parseInt(priceList?.children?.child10)}
    ]:
    [
      ...priceList?.adults?.map((elem,index)=>{
        return { "age_group":"adult", "num_people":parseInt(`${index+1}`),"price":elem['adult' + (index + 1)]}
      }),
      ...priceList?.children?.map((elem,index)=>{
        return { "age_group":"child", "num_people":parseInt(`${index+1}`),"price":elem['child' + (index + 1)]}
      }),
      // {"age_group":"adult", "num_people":1, "price": parseInt(priceList?.adults?.adult1)}, {"age_group":"adult", "num_people":2, "price": parseInt(priceList?.adults?.adult2)},
      // {"age_group":"adult", "num_people":3, "price": parseInt(priceList?.adults?.adult3)}, {"age_group":"adult", "num_people":4, "price": parseInt(priceList?.adults?.adult4)},
      // {"age_group":"adult", "num_people":5, "price": parseInt(priceList?.adults?.adult5)}, {"age_group":"adult", "num_people":6, "price": parseInt(priceList?.adults?.adult6)},
      // {"age_group":"adult", "num_people":7, "price": parseInt(priceList?.adults?.adult7)}, {"age_group":"adult", "num_people":8, "price": parseInt(priceList?.adults?.adult8)},
      // {"age_group":"adult", "num_people":9, "price": parseInt(priceList?.adults?.adult9)}, {"age_group":"adult", "num_people":10, "price": parseInt(priceList?.adults?.adult10)},
      // {"age_group":"child", "num_people":1, "price": parseInt(priceList?.children?.child1)}, {"age_group":"child", "num_people":2, "price": parseInt(priceList?.children?.child2)},
      // {"age_group":"child", "num_people":3, "price": parseInt(priceList?.children?.child3)}, {"age_group":"child", "num_people":4, "price": parseInt(priceList?.children?.child4)},
      // {"age_group":"child", "num_people":5, "price": parseInt(priceList?.children?.child5)}, {"age_group":"child", "num_people":6, "price": parseInt(priceList?.children?.child6)},
      // {"age_group":"child", "num_people":7, "price": parseInt(priceList?.children?.child7)}, {"age_group":"child", "num_people":8, "price": parseInt(priceList?.children?.child8)},
      // {"age_group":"child", "num_people":9, "price": parseInt(priceList?.children?.child9)}, {"age_group":"child", "num_people":10, "price": parseInt(priceList?.children?.child10)}
  ]
      // value
    });
    setCurrentPackage((data?.packages?.length && data?.packages[0]) || []);
    setTimeout(() => { setIsFeaturedErrorValidation(null); setIsDescriptionErrorValidation(false); setIsBriefDescriptionErrorValidation(false); setIsBriefDescriptionLengthErrorValidation(false) }, 2000);
    setIsLoading(false);
  }, [data]);
  useEffect(()=>{
    setState({
      ...state,
     edenred_product_code:currentPackage?.edenred_product_code,
    })

  },[currentPackage?.edenred_product_code])

  const handleUpdatePackageImage = (data) => {
    setCurrentPackage({
      ...currentPackage,
      images: data,
    });
    setIsImageErrorValidation(false)
    setIsImageThumbnailErrorValidation(false)
  };

  const handleAddRate=()=>{
    setAddHandleRate(true)
  }
  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    // if (data?.section?.name === "International") {              //edit package title getting  update all packeg title 
      setCurrentPackage(() => ({
        ...currentPackage,
        'pack_title': value.target.value,
      }));
      setIsUpdateData(true);
    // }
    setIsTitleEmptyErrorValidationn(false)
    setIsTitleLengthExceedErrorValidation(false)
  };
  const meta_title_change = (key, value) => {
    setMetaInfo(() => ({
      ...metaInfo,
      [key]: value.target.value,
    }));
    setIsError({ error: false, msg: '', name: "" })


  }
  const meta_description = (key, value) => {
    setMetaInfo(() => ({
      ...metaInfo,
      [key]: value.target.value,
    }));
    setIsError({ error: false, msg: '', name: "" })


  }

  //Changing Values of Input Fields
  const handleChangeValue = (key, value) => {
    setCurrentPackage(() => ({
      ...currentPackage,
      [key]: value.target.value,
    }));
    setIsUpdateData(true);
    if (key == "pack_title") {
      // setState(() => ({
      //   ...state,
      //   "title": value.target.value
      // }))
    } else if (key == "valid_till") {
      setIsValidTillErrorValidation(false)
    }
    setIsPackageEmptyErrorValidation(false)
    setIspackTitleErrorValidation(false)
    setIsPointsErrorValidation(false)
    setIsPointsLengthErrorValidation(false)
    setIsError({ error: false, msg: "", name: "" })
    setIsBriefDescriptionErrorValidation(false)
    setIsBriefDescriptionLengthErrorValidation(false)
  };

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSelect = (event) => {
    dispatch(holidayListingAction.selectHolidayDropdown());
    setSelect(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseSelect = (item) => {
    setSectionId(item.id)
    setDropdownValue(item.name);
    setIsOnchange(true);

    if (item?.international === true) {
      setIsGiftVoucher(true);
    } else setIsGiftVoucher(false);

    setSelect(null);
  };

  const handleClickDelete = (event, id) => {
    setPackageDropdown(event.currentTarget);
    setPackageId(id);
  };

  const handleError = (errorMsg) => {
    setIsFeaturedErrorValidation(errorMsg);
  }

  const handleClosePackage = () => {
    setPackageDropdown(null);
  };

  const handleAdd = () => {
    setCurrentPackage({
      ...currentPackage,
      // pack_title: "",
      price: '',
      // valid_till: "",
      // location: [
      //   {
      //     address: "",
      //   },
      // ],
      // images: [],
      // brief_description: "",
      // note: null,
      // description: "",
      // schedule_data: {},
      // location_city: "",
      // location_country: "",
      // best_seller: false,
      // covid_safe: false,
      revised_price: "",
      // featured_amenities: JSON.parse(JSON.stringify(FEATURED_AMENITIES_LIST)),
      // room_amenities: JSON.parse(JSON.stringify(ROOM_AMENITIES_LIST)),
      // hotel_resort_facilities: JSON.parse(JSON.stringify(RESORT_FACILITIES_LIST)),
      // location_type: "",
      // activity_type: "",
      // activity_level: "",
      // ages: "",
      // group_size: {min: "", max: ""},
      // itinerary: "",
      // accomodations: "",
      // essential_info: "",
      // faq: "",
    });
    // resData = ''
    setValue("");
    // setLocationCityvalue("")
  };


  const handleAddEvent = () => {
    setIsSave(true);
    handleAdd();
    setAnchorEl(null);
    setIsAddAnotherPackage(true)
    setDisable(true)
  };
  useEffect(() => {
    if (isAddAnotherPackage) {
      let oldImgIds = currentPackage?.images?.map(e => e.id)
      dispatch(holidayListingAction.isAddAnotherPackage(oldImgIds))
    } else {
      dispatch(holidayListingAction.isAddAnotherPackage([]))
    }
  }, [isAddAnotherPackage])
  const handleBestSeller = (event) => {
    setCurrentPackage({
      ...currentPackage,
      [event.target.name]: event.target.checked,
    });
  };

  const handleFlexiSchedule = (event) => {
    if(event.target.checked ){
      setScheduleType(null);

    }
    // scheduledData = '';
    setCurrentPackage({
      ...currentPackage,
      [event.target.name]: event.target.checked,
      schedule_data: event.target.checked ? {} : currentPackage?.schedule_data,
      schedule_type: event.target.checked ? null : currentPackage?.schedule_type
    });
    setScheduleType(null);
    setScheduleTypeUpdateBool(false);

  };
  const handleEnablePayment = (event) => {
    setCurrentPackage({
      ...currentPackage,
      [event.target.name]: event.target.checked,
    });
  };

  const openingSoonhandler = (event) => {
    if (event.target.checked === false) {
      setCurrentPackage({
        ...currentPackage,
        opening_soon: "",
      });
      setOpeningSoonDateValue("")
    } else if (currentPackage.opening_soon) return event.target.checked
    else { return event.preventDefault(), setOpeningSoon(true) }
  };

  const openingSoonLabel = () => {
    if (currentPackage.opening_soon !== "" && currentPackage.opening_soon !== null) return `Opening on ${currentPackage.opening_soon}`
    else {
      return "Opening Soon?"
    }
  };

  const dateChange = (date) => {
    setCurrentPackage({
      ...currentPackage,
      opening_soon: (date.format('YYYY-MM-DD'))

    });
    setOpeningSoon(false);
  };
  const handleFeaturedpackage = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const covidSafeHandle = (event) => {
    setCurrentPackage({
      ...currentPackage,
      [event.target.name]: event.target.checked,
    });
  };

  // const dealHandle = (event) => {
  //   setCurrentPackage({
  //     ...currentPackage,
  //     [event.target.name]: event.target.checked,
  //   });
  // };


  const deletePackage = (id) => {
    const packId = currentPackage?.corporate
    dispatch(
      holidayListingAction.deleteHolidayPackage(
        currentPackage,
        packId,
        handleDeleteSuccess
      )
    );
    setPackageDropdown(null)
  };

  const handleDeleteSuccess = (id) => {
    enqueueSnackbar("Deleted Successfully", {
      variant: "success",
    });
    dispatch(holidayListingAction.getHolidayPackageInfo(data.id));
    refreshList()
  };

  const handleDeleteHoliday = (id) => {
    setDeletePackItem(true)
    dispatch(holidayListingAction.setChosenPackIDBool(true))
    const packId = currentPackage?.corporate
    dispatch(holidayListingAction.deleteHoliday(id, packId, loader));
    setAnchorEl(null);


  };

  const loader = (id) => {
    refreshList(true)
    dispatch(holidayListingAction.editHolidayListItem(id));
  };


  const configJoditEditor = {
    readonly: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    placeholder: "Description...",
    addNewLineOnDBLClick: false,
    controls: {
      font: false,
    },
    height: "500px"

  };

  const setSchedules = (days, nights, scheduleType) => {
    setCurrentPackage((prevstate) => {
      return { ...prevstate, schedule_data: { days: days, nights: nights }, schedule_type: scheduleType };
    });
    setPopupSelect(false);
    setScheduleType(scheduleType)
  };

  const saveDateData = (date, scheduleType) => {
    setScheduleType(scheduleType);
    setScheduleTypeUpdateBool(true);
    setDateRangeDate(date);
    setCurrentPackage((prevstate) => {
      return { ...prevstate, schedule_data: { selectedDates: date }, schedule_type: scheduleType };
    });

    date.map((item, index) => {
      if (date.length == index + 1) {
        setCurrentPackage(() => ({
          ...currentPackage,
          schedule_data: { selectedDates: date },
          schedule_type: scheduleType,
          "valid_till": moment(item.to, "DD-MM-YYYY").format("yyyy-MM-DD"),
        }));
      }
    })
  };
  const saveDateDataBlockDates = (date) => {
    if(date==null){
      setCurrentPackage(
        {...currentPackage,
          restricted_booking_dates:null
  
  
        });
    
        setState({
          ...state,
          restricted_booking_dates:null
         })

    }
 
    else if(date){
      setCurrentPackage(
        {...currentPackage,
          restricted_booking_dates:{
            "selected_dates":date
          }
  
  
        });
    
        setState({
          ...state,
          restricted_booking_dates:{
            "selected_dates":date
          }
         })

    }
  
       setPopupDateBLock(false)


  };
  const updateDateDataBlockDates=(date)=>{
    console.log(currentPackage,"currentPackage");
    const newValue = 'new_value2';

// Update the value of the second key using object spread syntax
currentPackage = { ...currentPackage, restricted_booking_dates: newValue };

  }

  //ONCLICK OF SAVE POST OBJ WILL FORM AND PASS TO THE ACTION
  const handleUpdate =(event) => {

  //   checkSentence(currentPackage.pack_title)
  //   if(!symbolValidation){ 
    
  //                             setSymbolValidation(true)
  //                             return
  //  }

    setIsPackageEmptyErrorValidation(false);
    setIspackTitleErrorValidation(false);
    setIsLocationErrorValidation(false);
    setIsScheduleErrorValidation(false);
    setIsValidTillErrorValidation(false);
    setIsBriefDescriptionErrorValidation(false);
    setIsDescriptionErrorValidation(false);
    setIsBriefDescriptionLengthErrorValidation(false)
    setIsPointsErrorValidation(false);
    setIsPointsLengthErrorValidation(false);
    setIsImageErrorValidation(false);
    setIsImageThumbnailErrorValidation(false);
    setIsTitleEmptyErrorValidationn(false);
    setIsTitleLengthExceedErrorValidation(false);
    setCountryError(false)
    const postData = mapDataforPost(state);
    const htmltoText = (html) => {
      let text = html;
      text = text.replace(/\n/gi, "");
      text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
      text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
      text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, "");
      text = text.replace(/<\/div>/gi, "");
      text = text.replace(/<\/li>/gi, "");
      text = text.replace(/<li.*?>/gi, "  *  ");
      text = text.replace(/<\/ul>/gi, "");
      text = text.replace(/<\/p>/gi, "");
      text = text.replace(/<br\s*[\/]?>/gi, "");
      text = text.replace(/<[^>]+>/gi, "");
      text = text.replace(/^\s*/gim, "");
      text = text.replace(/ ,/gi, "");
      text = text.replace(/ +/gi, "");
      text = text.replace(/\n+/gi, "");
      text = text.replace('&nbsp;', "");
      return text;
    };
    if (htmltoText(content).replaceAll(' ', '').trim().length === 0) {
      setIsDescriptionErrorValidation(true);
      focusInput("#desc")
      return;
    }
    if (state.title === "" && data?.section?.name === "International") setIsTitleEmptyErrorValidationn(true)
    else if (state.title?.length > 150 ) setIsTitleLengthExceedErrorValidation(true)
    else if (currentPackage.pack_title === "") {
      setIsPackageEmptyErrorValidation(true)
      focusInput("#title")
    }
    
    else if (currentPackage.pack_title.length > 150) {
      setIspackTitleErrorValidation(true)
      focusInput("#title")
    }
    else if (currentPackage.location[0].address === "") {
      setIsLocationErrorValidation(true)
      focusInput("#location")
    }
    else if (addImage.length === 0) {
      setIsImageErrorValidation(true)
      focusInput("#images")
    }
    else if (!setThumbnail) setIsImageThumbnailErrorValidation(true)
    else if (data?.section?.name === "International" && currentPackage?.location_country === "") {
      setIsError({ error: true, name: "location_error", msg: COUNTRY_ERROR });
      focusInput("#locationDropdown")
    }
    else if (data?.section?.name !== "International" && currentPackage?.location_city === "") {
      setIsError({ error: true, name: "location_error", msg: CITY_ERROR });
      focusInput("#locationDropdown");
    }
    else if (Object.entries(currentPackage.schedule_data).length === 0 && !currentPackage.flexi_schedule) {
      setIsScheduleErrorValidation(true);
      focusInput(currentPackage?.package_type == "itinerary" ? "#desc" : "#schedule")
    }
    else if (!currentPackage?.location_type && currentPackage?.package_type == "staybased") {
      setIsError({ error: true, name: "location_type", msg: LOCATION_TYPE_EMPTY })
      focusInput("#locationType")
    }
    else if (currentPackage?.location_type?.length > 20 && currentPackage?.package_type == "staybased") {
      setIsError({ error: true, name: "location_type", msg: LOCATION_TYPE_CHAR_LIMIT })
      focusInput("#locationType")
    }
    else if (!currentPackage?.activity_type && currentPackage?.package_type == "itinerary") {
      setIsError({ error: true, name: "activity_type", msg: ACTIVITY_TYPE_EMPTY })
      focusInput("#itineraryFields")
    }
    else if (!currentPackage?.activity_level && currentPackage?.package_type == "itinerary") {
      setIsError({ error: true, name: "activity_level", msg: ACTIVITY_LEVEL_EMPTY })
      focusInput("#itineraryFields")
    }
    else if (!currentPackage?.ages && currentPackage?.package_type == "itinerary") {
      setIsError({ error: true, name: "ages", msg: AGES_EMPTY })
      focusInput("#itineraryFields")
    }


    else if (currentPackage.price === "") {
      setIsPointsErrorValidation(true)
      focusInput("#price")
    }

    else if(Object.values(priceList).every((category) =>
    Object.values(category).every((value) => value === null))){
      enqueueSnackbar("Please fill all the fields of add rate", {
        variant: "error",
    });
    }
    else if (currentPackage.price.length > 6) {
      setIsPointsLengthErrorValidation(true)
      focusInput("#price")
    }
    // else if (currentPackage?.revised_price == "" && currentPackage?.package_type ) setIsError({error: true, name: "revised_price", msg: REVISED_PRICE})
    else if (currentPackage?.revised_price?.length > 6 && currentPackage?.package_type) {
      setIsError({ error: true, name: "revised_price", msg: REVISED_PRICE_LENGTH })
      focusInput("#price")
    }
    else if (Number(currentPackage.price) <= Number(priceList?.adults[0]?.adult1)) {
      setIsError({ error: true, msg: REVISED_PRICE_ERROR, name: "revised_price" })
      focusInput("#price")
    }
    else if (currentPackage.valid_till === "") {
      setIsValidTillErrorValidation(true)
      focusInput("#validTill")
    }
    else if (currentPackage.brief_description === "") {
      setIsBriefDescriptionErrorValidation(true)
      focusInput("#brief")
    }
    else if (currentPackage.brief_description.length > 300) {
      setIsBriefDescriptionLengthErrorValidation(true)
      focusInput("#brief")
    }
    // else if (currentPackage?.group_size == null && currentPackage?.package_type  == "itinerary") setIsError({error: true, name: "group_size", msg: GUEST_EMPTY})
    // else if (!currentPackage?.group_size?.min && currentPackage?.package_type  == "itinerary") setIsError({error: true, name: "group_size", msg: GUEST_EMPTY})
    // else if (!currentPackage?.group_size?.max && currentPackage?.package_type  == "itinerary") setIsError({error: true, name: "group_size", msg: GUEST_EMPTY})
    else if ((Number(currentPackage?.group_size?.min) > Number(currentPackage?.group_size?.max)) && currentPackage?.package_type) {
      setIsError({ error: true, name: "group_size", msg: GUEST_ERROR })
      focusInput("#groupSize")
    }
    else if (checkFeaturedAmenitiesLength(currentPackage.featured_amenities) && currentPackage.package_type == "staybased") {
      setIsError({ error: true, name: "featured_amenities", msg: FEATURED_AMENITIES_ERROR });
      focusInput("#amenities")
    }
    // else if (content === "" || content === undefined) setIsDescriptionErrorValidation(true) 
    else {
      if (!isAddAnotherPackage) {
        setIsLoading(true);
     
        dispatch(holidayListingAction.listHolidayDataUpdate(postData, refreshList, handleSuccess, data.id, handleError));
        dispatch(holidayListingAction.handleOpeningSoonDate(''));
        setDateValueBool(false);
      } else {
        setIsLoading(true);
        dispatch(
          holidayListingAction.handleAddNewHolidayPackage(postData, refresPackagehList)
        );
        setIsAddAnotherPackage(false);
        setIsUpdateData(false)
      }
    }
    // setValue(null)
    dispatch(holidayListingAction.holidayListsUpdateBool(true));
  };
  const handleSuccess = () => {
    enqueueSnackbar("Updated Successfully", {
      variant: "success",
    });
  };
  const refresPackagehList = (id) => {
    refreshList()
    dispatch(holidayListingAction.getHolidayPackageInfo(data.id));
    enqueueSnackbar("Package Added successfully.", {
      variant: "success",
    });
    setIsSave(false);
    setIsLoading(false);
  };
  const refreshList = (fromDelete) => {
    setIsLoading(false);
    if (search !== "") {
      dispatch(holidayListingAction.searchHolidayListing(search, holidayListingDropDownVal?.id, pageID));
    } else {
      dispatch(holidayListingAction.getHolidayList(pageID, holidayListingDropDownVal?.id));
      dispatch(holidayListingAction.holidaySearchValue(""));
    }
    if (data?.id && !fromDelete) {
      dispatch(holidayListingAction.getHolidayPackageInfo(data.id));
    }
  };

  const handleAddPackage = (id) => {
    setIsPackageEmptyErrorValidation(false)
    setIspackTitleErrorValidation(false);
    setIsLocationErrorValidation(false);
    setIsScheduleErrorValidation(false);
    setIsValidTillErrorValidation(false);
    setIsBriefDescriptionErrorValidation(false);
    setIsDescriptionErrorValidation(false);
    setIsBriefDescriptionLengthErrorValidation(false)
    setIsPointsErrorValidation(false);
    setIsPointsLengthErrorValidation(false);
    setIsImageErrorValidation(false);
    setIsImageThumbnailErrorValidation(false)

    let tempImages = addImage.map((item, index) => {

      return {
        name: item?.name,
        image: item.image,
        thumbnail: item.thumbnail,
        media_type: item.media_type,
        // video: item.video,
        video: item.video || '',
        priority: index + 1
      };
    });
    const objData = {
      section: data?.section?.id,
      holiday_id: data.id,
      pack_id: currentPackage?.id || null,
      pack_title: currentPackage?.pack_title .trim().replace(/\s+/g, ' ') || "",
      brief_description: currentPackage?.brief_description || "",
      note: currentPackage?.note || null,
      location: currentPackage?.location || [],
      schedule_type: currentPackage?.schedule_type,
      images: tempImages,
      schedule_data: currentPackage?.schedule_data,
      valid_till: currentPackage?.valid_till || "",
      price: currentPackage?.price || null,
      best_seller: currentPackage?.best_seller,
      deal: currentPackage?.deal,
      covid_safe: currentPackage?.covid_safe,
      opening_soon: currentPackage.opening_soon,
      description: content,
      location_city: currentPackage.location_city || '',
      location_country: currentPackage.location_country || '',
      flexi_schedule: currentPackage.flexi_schedule,
      featured: currentPackage?.featured,
      restricted_booking_dates:currentPackage?.restricted_booking_dates!==" " && currentPackage?.restricted_booking_dates?currentPackage?.restricted_booking_dates:null,
      enable_payment: currentPackage?.enable_payment,
      price_list:currentPackage?.price_list?.adult_prices?.length>0 && !isSave?[
        {"id":currentPackage?.price_list?.adult_prices[0].id,"age_group":"adult", "num_people":1, "price": parseInt(priceList?.adults?.adult1)}, {"id":currentPackage?.price_list?.adult_prices[1].id,"age_group":"adult", "num_people":2, "price": parseInt(priceList?.adults?.adult2)},
        {"id":currentPackage?.price_list?.adult_prices[2].id,"age_group":"adult", "num_people":3, "price": parseInt(priceList?.adults?.adult3)}, {"id":currentPackage?.price_list?.adult_prices[3].id,"age_group":"adult", "num_people":4, "price": parseInt(priceList?.adults?.adult4)},
        {"id":currentPackage?.price_list?.adult_prices[4].id,"age_group":"adult", "num_people":5, "price": parseInt(priceList?.adults?.adult5)}, {"id":currentPackage?.price_list?.adult_prices[5].id,"age_group":"adult", "num_people":6, "price": parseInt(priceList?.adults?.adult6)},
        {"id":currentPackage?.price_list?.adult_prices[6].id,"age_group":"adult", "num_people":7, "price": parseInt(priceList?.adults?.adult7)}, {"id":currentPackage?.price_list?.adult_prices[7].id,"age_group":"adult", "num_people":8, "price": parseInt(priceList?.adults?.adult8)},
        {"id":currentPackage?.price_list?.adult_prices[8].id,"age_group":"adult", "num_people":9, "price": parseInt(priceList?.adults?.adult9)}, {"id":currentPackage?.price_list?.adult_prices[9].id,"age_group":"adult", "num_people":10, "price": parseInt(priceList?.adults?.adult10)},
        {"id":currentPackage?.price_list?.child_prices[0].id,"age_group":"child", "num_people":1, "price": parseInt(priceList?.children?.child1)}, {"id":currentPackage?.price_list?.child_prices[1].id,"age_group":"child", "num_people":2, "price": parseInt(priceList?.children?.child2)},
        {"id":currentPackage?.price_list?.child_prices[2].id,"age_group":"child", "num_people":3, "price": parseInt(priceList?.children?.child3)}, {"id":currentPackage?.price_list?.child_prices[3].id,"age_group":"child", "num_people":4, "price": parseInt(priceList?.children?.child4)},
        {"id":currentPackage?.price_list?.child_prices[4].id,"age_group":"child", "num_people":5, "price": parseInt(priceList?.children?.child5)}, {"id":currentPackage?.price_list?.child_prices[5].id,"age_group":"child", "num_people":6, "price": parseInt(priceList?.children?.child6)},
        {"id":currentPackage?.price_list?.child_prices[6].id,"age_group":"child", "num_people":7, "price": parseInt(priceList?.children?.child7)}, {"id":currentPackage?.price_list?.child_prices[7].id,"age_group":"child", "num_people":8, "price": parseInt(priceList?.children?.child8)},
        {"id":currentPackage?.price_list?.child_prices[8].id,"age_group":"child", "num_people":9, "price": parseInt(priceList?.children?.child9)}, {"id":currentPackage?.price_list?.child_prices[9].id,"age_group":"child", "num_people":10, "price": parseInt(priceList?.children?.child10)}
    ]:
    [
      {"age_group":"adult", "num_people":1, "price": parseInt(priceList?.adults?.adult1)}, {"age_group":"adult", "num_people":2, "price": parseInt(priceList?.adults?.adult2)},
      {"age_group":"adult", "num_people":3, "price": parseInt(priceList?.adults?.adult3)}, {"age_group":"adult", "num_people":4, "price": parseInt(priceList?.adults?.adult4)},
      {"age_group":"adult", "num_people":5, "price": parseInt(priceList?.adults?.adult5)}, {"age_group":"adult", "num_people":6, "price": parseInt(priceList?.adults?.adult6)},
      {"age_group":"adult", "num_people":7, "price": parseInt(priceList?.adults?.adult7)}, {"age_group":"adult", "num_people":8, "price": parseInt(priceList?.adults?.adult8)},
      {"age_group":"adult", "num_people":9, "price": parseInt(priceList?.adults?.adult9)}, {"age_group":"adult", "num_people":10, "price": parseInt(priceList?.adults?.adult10)},
      {"age_group":"child", "num_people":1, "price": parseInt(priceList?.children?.child1)}, {"age_group":"child", "num_people":2, "price": parseInt(priceList?.children?.child2)},
      {"age_group":"child", "num_people":3, "price": parseInt(priceList?.children?.child3)}, {"age_group":"child", "num_people":4, "price": parseInt(priceList?.children?.child4)},
      {"age_group":"child", "num_people":5, "price": parseInt(priceList?.children?.child5)}, {"age_group":"child", "num_people":6, "price": parseInt(priceList?.children?.child6)},
      {"age_group":"child", "num_people":7, "price": parseInt(priceList?.children?.child7)}, {"age_group":"child", "num_people":8, "price": parseInt(priceList?.children?.child8)},
      {"age_group":"child", "num_people":9, "price": parseInt(priceList?.children?.child9)}, {"age_group":"child", "num_people":10, "price": parseInt(priceList?.children?.child10)}
  ]
    };
    if (currentPackage.pack_title === "") setIsPackageEmptyErrorValidation(true)
    else if(Object.values(priceList).every((category) =>
    Object.values(category).every((value) => value === null))){
      enqueueSnackbar("Please fill all the fields of add rate", {
        variant: "error",
    });
    }
    else if (currentPackage.pack_title.length > 150) setIspackTitleErrorValidation(true)
    else if (currentPackage.location[0].address === "") setIsLocationErrorValidation(true)
    else if (addImage.length === 0) setIsImageErrorValidation(true)
    else if (!setThumbnail) setIsImageThumbnailErrorValidation(true);
    else if (data?.section?.name === "International" && currentPackage.location_country === "") setCountryError(true)
    else if (Object.entries(currentPackage.schedule_data).length === 0 && !currentPackage.flexi_schedule) setIsScheduleErrorValidation(true)
    // else if (data?.section?.name !== "International" && currentPackage.schedule_data === undefined || currentPackage.schedule_data.days === "" && currentPackage.schedule_data.nights === "" || currentPackage?.schedule_data?.selectedDates?.length === 0) setIsScheduleErrorValidation(true)
    else if (currentPackage.price === "" || currentPackage.price === 0) setIsPointsErrorValidation(true)
    else if (currentPackage.price.length > 6) setIsPointsLengthErrorValidation(true)
    else if (currentPackage.valid_till === "") setIsValidTillErrorValidation(true)
    else if (currentPackage.brief_description === "") setIsBriefDescriptionErrorValidation(true)
    else if (currentPackage.brief_description.length > 300) setIsBriefDescriptionLengthErrorValidation(true)
    else if (content === "" || content === undefined) setIsDescriptionErrorValidation(true)
 
    else {
      setIsLoading(true);
      dispatch(
        holidayListingAction.handleAddNewHolidayPackage(objData, refresPackagehList)
      );
      setIsAddAnotherPackage(false);
    };
    setIsUpdateData(false)
  };

  const getModifiedHtml = (html) => {
    if (html?.startsWith('<div')) {
      return html
    } else {
      return `<div>${html}</div>`;
    }
  };

  const setLocation = (address, latitude, longitude, city, state, country) => {
    const obj = {
      address: address,
      latitude: latitude,
      longitude: longitude,
      city: city,
      state: state,
      country: country
    }
    const arr = currentPackage?.location;
    arr[0] = obj;
    setCurrentPackage((prevstate) => {
      return {
        ...prevstate,
        location: arr,
        location_country: country,
      };
    });
  };

  useEffect(()=>{
    dispatch(bookingAction.selectBlockDatesRestrictions(currentPackage))

  },[currentPackage])

  function mapDataforPost(packageData) {
    let tempImages = addImage.map((item, index) => {
      if (item.isUpdate) {

        return {
          name: item?.name,
          image: item.image,
          thumbnail: item.thumbnail,
          media_type: item.media_type,
          video: item.video,
          priority: index + 1,
          alt_text:item.alt_txt||"",
          title_text:item.title_txt||""

        };
      } else {
        return item;
      }
    })
    const obj = {
      section: !isOnchange ? data?.section?.id : sectionId,
      package_type: currentPackage?.package_type,
      title: packageData.title.trim().replace(/\s+/g, ' '),
      holiday_id: data.id,
      pack_id: currentPackage?.id,
      pack_title: currentPackage["pack_title"].trim().replace(/\s+/g, ' '),
      meta_info: currentPackage?.meta_info ? currentPackage?.meta_info : packageData?.meta_info,
      edenred_product_code:currentPackage?.edenred_product_code,
      brief_description: currentPackage.brief_description,
      disable_tomorrows_bookings:currentPackage?.disable_tomorrows_bookings,
      restricted_booking_dates:currentPackage?.restricted_booking_dates!=" "&&currentPackage?.restricted_booking_dates?currentPackage?.restricted_booking_dates:null,
      note: currentPackage.note,
      images: tempImages,
      location: currentPackage?.location || [],
      schedule_type: scheduleTypeUpdateBool ? scheduleType : currentPackage?.schedule_type,
      schedule_data: currentPackage["schedule_data"],
      blocked_dates: [{ "date": "2021-08-25" }, { "date": "2021-08-27" }],
      valid_till: currentPackage?.valid_till,
      price: currentPackage?.price,
      best_seller: currentPackage?.best_seller,
      deal: currentPackage.deal,
      covid_safe: currentPackage.covid_safe,
      opening_soon: currentPackage?.opening_soon ? currentPackage?.opening_soon : null,
      featured: true,
      featured_title: "",
      description: content,
      location_city: currentPackage.location_city || '',
      location_country: currentPackage.location_country || '',
      flexi_schedule: currentPackage.flexi_schedule,
      featured: state.featured,
      enable_payment: currentPackage.enable_payment,
      revised_price: currentPackage?.price_list?.adult_prices?.length>0?priceList?.adults?.adult1:currentPackage?.revised_price == "" ? 0 : currentPackage?.revised_price,
      featured_amenities: currentPackage?.featured_amenities ? currentPackage?.featured_amenities : packageData?.featured_amenities,
      room_amenities: currentPackage?.room_amenities ? currentPackage?.room_amenities : packageData?.room_amenities,
      hotel_resort_facilities: currentPackage?.hotel_resort_facilities ? currentPackage?.hotel_resort_facilities : packageData?.hotel_resort_facilities,
      location_type: currentPackage?.location_type,
      group_size: currentPackage?.group_size,
      activity_type: currentPackage?.activity_type,
      activity_level: currentPackage?.activity_level,
      ages: currentPackage?.ages,
      itinerary: currentPackage.itinerary,
      accomodations: currentPackage.accomodations,
      essential_info: currentPackage.essential_info,
      faq: currentPackage.faq,
      average_review_count: currentPackage.average_review_count,
      total_review_counts: currentPackage.total_review_counts,
      price_list:currentPackage?.price_list?.adult_prices?.length>0 && !isSave?[
      ...priceList?.adults?.map((elem,index)=>{
        return {   ...(elem.id && { id: elem.id }),"age_group":"adult", "num_people":parseInt(`${index+1}`),"price":elem['adult' + (index + 1)]}
      }),
      ...priceList?.children?.map((elem,index)=>{
        return {  ...(elem.id && { id: elem.id }), "age_group":"child", "num_people":parseInt(`${index+1}`),"price":elem['child' + (index + 1)]}
      }),
      ...toDeleteIds?.map((elem)=>{
        return {...elem,"delete":true}
      })
    ]:
    [
      ...priceList?.adults?.map((elem,index)=>{
        return { "age_group":"adult", "num_people":parseInt(`${index+1}`),"price":elem['adult' + (index + 1)]}
      }),
      ...priceList?.children?.map((elem,index)=>{
        return { "age_group":"child", "num_people":parseInt(`${index+1}`),"price":elem['child' + (index + 1)]}
      }),
    ]
  //     currentPackage?.price_list?.adult_prices?.length>0 && !isSave?[
  //       ...currentPackage?.price_list?.adult_prices?.map((elem,index)=>{
  //         return {"id": elem.id,"age_group":"adult", "num_people":(index + 1),"price":elem.price}
  //       }),
  //       ...currentPackage?.price_list?.child_prices?.map((elem,index)=>{
  //         return {"id": elem.id,"age_group":"child", "num_people":(index + 1),"price":elem.price}

  //       })
  //       {"id":currentPackage?.price_list?.adult_prices[0].id,"age_group":"adult", "num_people":1, "price": parseInt(priceList?.adults?.adult1)}, {"id":currentPackage?.price_list?.adult_prices[1].id,"age_group":"adult", "num_people":2, "price": parseInt(priceList?.adults?.adult2)},
  //       {"id":currentPackage?.price_list?.adult_prices[2].id,"age_group":"adult", "num_people":3, "price": parseInt(priceList?.adults?.adult3)}, {"id":currentPackage?.price_list?.adult_prices[3].id,"age_group":"adult", "num_people":4, "price": parseInt(priceList?.adults?.adult4)},
  //       {"id":currentPackage?.price_list?.adult_prices[4].id,"age_group":"adult", "num_people":5, "price": parseInt(priceList?.adults?.adult5)}, {"id":currentPackage?.price_list?.adult_prices[5].id,"age_group":"adult", "num_people":6, "price": parseInt(priceList?.adults?.adult6)},
  //       {"id":currentPackage?.price_list?.adult_prices[6].id,"age_group":"adult", "num_people":7, "price": parseInt(priceList?.adults?.adult7)}, {"id":currentPackage?.price_list?.adult_prices[7].id,"age_group":"adult", "num_people":8, "price": parseInt(priceList?.adults?.adult8)},
  //       {"id":currentPackage?.price_list?.adult_prices[8].id,"age_group":"adult", "num_people":9, "price": parseInt(priceList?.adults?.adult9)}, {"id":currentPackage?.price_list?.adult_prices[9].id,"age_group":"adult", "num_people":10, "price": parseInt(priceList?.adults?.adult10)},
  //       {"id":currentPackage?.price_list?.child_prices[0].id,"age_group":"child", "num_people":1, "price": parseInt(priceList?.children?.child1)}, {"id":currentPackage?.price_list?.child_prices[1].id,"age_group":"child", "num_people":2, "price": parseInt(priceList?.children?.child2)},
  //       {"id":currentPackage?.price_list?.child_prices[2].id,"age_group":"child", "num_people":3, "price": parseInt(priceList?.children?.child3)}, {"id":currentPackage?.price_list?.child_prices[3].id,"age_group":"child", "num_people":4, "price": parseInt(priceList?.children?.child4)},
  //       {"id":currentPackage?.price_list?.child_prices[4].id,"age_group":"child", "num_people":5, "price": parseInt(priceList?.children?.child5)}, {"id":currentPackage?.price_list?.child_prices[5].id,"age_group":"child", "num_people":6, "price": parseInt(priceList?.children?.child6)},
  //       {"id":currentPackage?.price_list?.child_prices[6].id,"age_group":"child", "num_people":7, "price": parseInt(priceList?.children?.child7)}, {"id":currentPackage?.price_list?.child_prices[7].id,"age_group":"child", "num_people":8, "price": parseInt(priceList?.children?.child8)},
  //       {"id":currentPackage?.price_list?.child_prices[8].id,"age_group":"child", "num_people":9, "price": parseInt(priceList?.children?.child9)}, {"id":currentPackage?.price_list?.child_prices[9].id,"age_group":"child", "num_people":10, "price": parseInt(priceList?.children?.child10)}
  //   ]:
  //   [
  //     ...priceList?.adults?.map((elem,index)=>{
  //       return {   ...(elem.id && { id: elem.id }),"age_group":"adult", "num_people":parseInt(`${index+1}`),"price":elem['adult' + (index + 1)]}
  //     }),
  //     ...priceList?.children?.map((elem,index)=>{
  //       return {  ...(elem.id && { id: elem.id }), "age_group":"child", "num_people":parseInt(`${index+1}`),"price":elem['child' + (index + 1)]}
  //     }),
  //     {"age_group":"adult", "num_people":1, "price": parseInt(priceList?.adults?.adult1)}, {"age_group":"adult", "num_people":2, "price": parseInt(priceList?.adults?.adult2)},
  //     {"age_group":"adult", "num_people":3, "price": parseInt(priceList?.adults?.adult3)}, {"age_group":"adult", "num_people":4, "price": parseInt(priceList?.adults?.adult4)},
  //     {"age_group":"adult", "num_people":5, "price": parseInt(priceList?.adults?.adult5)}, {"age_group":"adult", "num_people":6, "price": parseInt(priceList?.adults?.adult6)},
  //     {"age_group":"adult", "num_people":7, "price": parseInt(priceList?.adults?.adult7)}, {"age_group":"adult", "num_people":8, "price": parseInt(priceList?.adults?.adult8)},
  //     {"age_group":"adult", "num_people":9, "price": parseInt(priceList?.adults?.adult9)}, {"age_group":"adult", "num_people":10, "price": parseInt(priceList?.adults?.adult10)},
  //     {"age_group":"child", "num_people":1, "price": parseInt(priceList?.children?.child1)}, {"age_group":"child", "num_people":2, "price": parseInt(priceList?.children?.child2)},
  //     {"age_group":"child", "num_people":3, "price": parseInt(priceList?.children?.child3)}, {"age_group":"child", "num_people":4, "price": parseInt(priceList?.children?.child4)},
  //     {"age_group":"child", "num_people":5, "price": parseInt(priceList?.children?.child5)}, {"age_group":"child", "num_people":6, "price": parseInt(priceList?.children?.child6)},
  //     {"age_group":"child", "num_people":7, "price": parseInt(priceList?.children?.child7)}, {"age_group":"child", "num_people":8, "price": parseInt(priceList?.children?.child8)},
  //     {"age_group":"child", "num_people":9, "price": parseInt(priceList?.children?.child9)}, {"age_group":"child", "num_people":10, "price": parseInt(priceList?.children?.child10)}
  // ]
  //   };
    }
    return obj;
  }

  const imageThubnail = addImage.find((img) => img.thumbnail) + currentPackage?.images;
  const currentImage = currentImages?.find((img) => img.thumbnail);

  const setPackageDetails = (id) => {
    setSelectedPackage(id)
  };

  if (currentPackage.schedule_type === 'package') {
    resData = scheduleDaysVal(currentPackage?.schedule_data?.days) + scheduleNightsVal(currentPackage?.schedule_data?.nights);
  } else if (currentPackage?.schedule_type === 'date_range') {
    resData = currentPackage?.schedule_data?.selectedDates && currentPackage?.schedule_data?.selectedDates !== null && currentPackage?.schedule_data?.selectedDates.map(item => `${item.from} to ${item.to}`);
  } else {
    resData = ""
  };

  const postLocationCityData = (loctionData) => {
    setIsError({ error: false, name: "", msg: "" })
    if (data?.section?.name === "International") {
      setCurrentPackage({
        ...currentPackage,
        location_country: loctionData
      })
    } else {
      setCurrentPackage({
        ...currentPackage,
        location_city: loctionData
      })
    }
  };
  const handleBlockTommorow=(event)=>{
    
    if (event.target.checked === true) {
    setCurrentPackage({
      ...currentPackage,
      disable_tomorrows_bookings: true
    })
   setState({
    ...state,
    disable_tomorrows_bookings: true
   })
  }
else{
  setCurrentPackage({
    ...currentPackage,
    disable_tomorrows_bookings: false
  })
 setState({
  ...state,
  disable_tomorrows_bookings: false
 })

}
  }
  function checkSentence(title) {
    const allowedRegex = /[@#*()\-\_,]/;
    const notAllowedRegex = /[~`!$%^&+=\{\}\[\]|\\/:;"‘<>.?]/;

    for (let i = 0; i < title.length; i++) {
        const char = title.charAt(i);
     if (notAllowedRegex.test(char)) {
      setSymbolValidation(true)
      setSymbolErrorMsg(`${char} -> now allowed in title please remove !`)
    
      enqueueSnackbar(`${char} -> now allowed in title please remove !`, {
        variant: "error",
    });

           return 
        } 
        else{
          setSymbolValidation(false)
          setSymbolErrorMsg(false)

        }
    }
}
  const handlePackageType = (val) => {
    setCurrentPackage({
      ...currentPackage,
      package_type: val
    })
  }
  const deleteHolidayPackage = (id) => {
    if (window.confirm("Are you sure to delete this Holiday!") == true) {
      handleDeleteHoliday(id)
    } else {
      setAnchorEl(null);
    }

  };
  const ACTIVITY_LEVEL_VALUE = (val) => {
    let value = "";
    ACTIVITY_LEVEL_OPTIONS.map(item => {
      if (item.id == val) {
        value = item.name
      }
    })
    return value
  }



  const priceErrorLabel = isPointsErrorValidation ? PRICE : isPointsLengthErrorValidation ? PRICE_LENGTH : null
  const priceCondition = !isGiftVoucher && !apiGiftVoucher && currentPackage?.package_type;
  const isStaybased = currentPackage && currentPackage?.package_type == "staybased";
  const isItinerary = currentPackage && currentPackage?.package_type == "itinerary";




  return (
    <div>
      {isLoading && <div style={{ marginLeft: 10, marginBottom: -3 }}><LinearProgress /></div>}
      <div className="packageDetails-holder">
        <form>
          <div>
            <div className="form-options">
              <div>
                <h2>Editing</h2>
              </div>
              <div>
                <div className="form-btns d-flex align-items-center">
                  <div style={{}}>
                    <FeaturedPackage featured={state.featured} handleFeaturedpackage={handleFeaturedpackage} />
                  </div>
                  <div
                    className="section-dropdown"
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      width: "auto",
                    }}
                  >
                    <Button
                      style={{ paddingRight: "32px" }}
                      aria-controls="section-menu"
                      aria-haspopup="true"
                      disabled={data?.section?.name === "International" || disable ? true : false}
                      onClick={handleClickSelect}
                      onChange={(event) => setDropdownValue(event.target.value)} >
                      {isEmptyResultsArrayBool ? '' : !isOnchange ? data?.section?.name : dropdownValue}
                      <img
                        src={ArrowDown}
                        style={{ position: "absolute", right: "12px", top: `${isEmptyResultsArrayBool ? "4px" : "12px"}` }}
                        onClick={() => { }}
                      />
                    </Button>
                    <Menu
                      id="section-menu"
                      anchorEl={select}
                      keepMounted
                      open={Boolean(select)}
                      onClose={() => setSelect(null)}
                    >
                      {resultedLists &&
                        resultedLists?.map((item) => {
                          return (
                            <MenuItem
                              onClick={() => handleCloseSelect(item)}
                              key={item?.id}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                    </Menu>

                  </div>
                  <div>
                    {!isSave ? (
                      <button
                        type="button"
                        className="btn btn-primary btn-save"
                        onClick={handleUpdate}
                        disabled={isLoading}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary btn-save"
                        onClick={() => handleUpdate()}
                        disable={isLoading}
                      >
                        Add
                      </button>
                    )}
                  </div>
                  <div className="">
                    <Button
                      className="menu-option"
                      aria-controls="list-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </Button>
                    <Menu
                      id="list-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {state.packages?.length !== 3 && <MenuItem onClick={state.packages?.length == 3 ? "" : handleAddEvent}>
                        {apiGiftVoucher === true || data?.section?.name === "nternational" || dropdownValue === "nternational" ? null : "Add another package"}
                      </MenuItem>}
                      <MenuItem onClick={() => deleteHolidayPackage(data?.id)}>
                        Delete this holiday
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
                <div>
                  <MoveToCorporate  section={"holidays"} copyToCorp={currentPackage?.copy_to_crop} packSlug={data?.slug} refreshList={refreshList} isInternational={data?.section?.name === "International"} currentPackage={currentPackage}
                    data={data} />
                </div>
              </div>
            </div>
            {isFeaturedErrorValidation && <p style={{ textAlign: "end", marginRight: "9%", color: "red" }}>{isFeaturedErrorValidation}</p>}



            <div>
              {!isGiftVoucher && apiGiftVoucher !== true && !data?.section?.name ? null :
                <div
                  className="form-group form-custom"
                  style={{ position: "relative" }}
                >
                  <label for=""> Listing Title *</label>
                  <input
                    value={isEmptyResultsArrayBool ? '' : state?.title}
                    type="text"
                    disabled={disable}
                    className="form-control"
                    onChange={(value) => handleChange("title", value)}
                  />




                  {isTitleEmptyErrorValidation ? <p style={{ color: "red" }}>Please add title</p> : ""}
                  {isTitleLengthExceedErrorValidation ? <p style={{ color: "red" }}>Listing  Title should not be more than 150 characters</p> : ""}
                  {symbolValidation?<Error error={symbolErrorMsg}/>:""}
               
                </div>}
   
            </div>
            {data?.section?.name &&
              <div style={{
                display: "flex",
                alignItems: "center", width: '700px', overflowX: 'scroll', paddingBottom: '10px', borderBottom: '1px solid #D0D0D0'
              }}>
                <h6 style={{ marginRight: 10 }}>Packages: </h6>
                {isEmptyResultsArrayBool ? '' :
                  state.packages?.length &&
                  state.packages.map((item, index) => {
                    return (
                      <div
                        className={!isAddAnotherPackage && item.id === selectedPackage ? classes.selectedPackage : classes.package}

                        style={{ marginRight: 12, borderRadius: '5px', }}
                        onClick={() => { setCurrentPackage(state.packages[index]); setPackageDetails(item.id); setIsAddAnotherPackage(false); setIsSave(false); setDisable(false) }}
                      >
                        <div
                          className={item?.valid_pack ? classes.activePoints : classes.inActivePoints}
                          style={{ height: 'auto', position: "relative" }}
                          onClick={() => { setPackageDetails(item.id); setIsAddAnotherPackage(false); setIsSave(false) }}
                        >
                          <h5
                            className={!isAddAnotherPackage && item.id === selectedPackage ? classes.selectedPoints : classes.points}
                          >
                            Rs&nbsp;{item?.price_list?.adult_prices[0]?.price>0?item?.price_list?.adult_prices[0]?.price :item?.revised_price>0?item?.revised_price: item.price}
                          </h5>
                          <div style={{
                            position: 'absolute',
                            height: '17%',
                            paddingBottom: '32px',
                            width: '1px',
                            left: '109px',
                            top: '2px',
                            backgroundColor: '#f9f9f9',
                          }}
                          />
                          {state.packages.length > 1 &&
                            <div
                              style={{ position: 'absolute', top: '12px', left: '141px' }}
                              onClick={(event) => handleClickDelete(event, item.id)}
                            >
                              {isAddAnotherPackage || item.id !== selectedPackage ?
                                (<img
                                  src={ArrowWhite}
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "2px",
                                  }}
                                />) :
                                (<img
                                  src={ArrowDown}
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "2px",
                                  }}
                                />)}

                            </div>
                          }
                          {packageDropdown && state.packages.length > 1 &&
                            <Menu
                              id="list-menu"
                              anchorEl={packageDropdown}
                              keepMounted
                              open={Boolean(packageDropdown)}
                              onClose={handleClosePackage}
                            >
                              <MenuItem onClick={() => deletePackage(packageId)}>
                                Delete this package
                              </MenuItem>
                            </Menu>
                          }
                        </div>
                      </div>
                    );
                  })}
                {isAddAnotherPackage &&
                  <div style={{ marginRight: 12, border: '1px solid black', borderRadius: '5px', }} className={classes.selectedPackage}>
                    <div style={{ height: 'auto', position: "relative" }} className={classes.selectedPackage}>
                      <h5 className={classes.selectedPoints}>Rs 000</h5>
                      <div style={{
                        position: 'absolute',
                        height: '17%',
                        paddingBottom: '32px',
                        width: '1px',
                        left: '109px',
                        top: '2px',
                        backgroundColor: 'rgb(202 194 194)',
                      }}
                      />
                      <Button
                        style={{ position: 'absolute', top: '12px', left: '141px' }}
                        aria-haspopup="true"
                      >
                        <img
                          src={ArrowWhite}
                          style={{
                            position: "absolute",
                            right: "74px",
                            top: "2px",
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
          <PackTypeButtons
            state={currentPackage}
            setState={setCurrentPackage}
            label={true}
            disable={disable}
          />
          <div className="overflow--scroll" id="wrapper">
            {!isGiftVoucher && apiGiftVoucher !== true ? (
              <div
                className="form-group form-custom"
                style={{ position: "relative" }}
                id="title"
              >
                <label for="">Package Title *</label>
                <input
                  value={isEmptyResultsArrayBool ? '' : currentPackage && currentPackage.pack_title}
                  type="text"
                  className="form-control"
                  onChange={(value) => handleChangeValue("pack_title", value)}
                />

                {isPackageEmptyErrorValidation ?
                  <p style={{ color: "red" }}>Please add package title</p> :
                  ispackTitleErrorValidation === true ?
                    <p style={{ color: "red" }}>Package Title Should Be Less Than 150 Characters</p>
                    : ""}
                <img
                  src={Edit}
                  style={{ position: "absolute", right: "35px", top: "55px" }}
                />
              </div>
            ) : null}

            {/*COLUMN 1 START*/}
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'row' }} id="images">
              <div style={{ flexDirection: 'column' }}>
                <div
                  onClick={() => setImageSelect(true)}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    width: 320,
                    height: 200,
                    paddingTop: "15px"
                  }}
                >
                  <img
                    src={isEmptyResultsArrayBool ? DefaultImage : setThumbnail ? setThumbnail.image : DefaultImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 14,
                      backgroundColor: "#CECECE",
                    }}
                  />
                  <h6
                    style={{
                      color: "white",
                      backgroundColor: "rgb(82, 82, 82)",
                      position: "absolute",
                      bottom: 20,
                      left: (data.section.name === "International") ? '100px' : '80px',
                      border: "1px solid white",
                      padding: 6,
                      borderRadius: "6px",
                    }}
                  >
                    <span style={{ padding: "0px 5px" }}>
                      <FaFileUpload />
                    </span>
                    {isEmptyResultsArrayBool ? '' : addImage.length > 0
                      ? addImage.length
                      : currentPackage?.images?.length}{" "}
                    {/* images / {data?.section?.name === "International" || dropdownValue === "International" ?'':'videos'}  */}
                    {/* {data.section.name === "International" || dropdownValue === "International" ? 'images' : 'images / videos'} */}
                    {'images / videos'}
                  </h6>
                </div>
                {isImageErrorValidation ?
                  <p style={{ color: 'red' }}>Please add atleast one image</p> :
                  isImageThumbnailErrorValidation ?
                    <p style={{ color: 'red' }}>Please select thumbnail image</p>
                    : ""}
                {/* {data?.section?.name === "International" || dropdownValue === "International" ? null : */}

                <div>
                  {priceCondition ?
                     <div className="form-group form-custom"> 

            
              
              


                      <div className="package-price" id="price">
                        <div className="form-group form-custom">
                          <h6>Rs</h6>
                          <Input
                            label="Original Rate *"
                            value={isEmptyResultsArrayBool ? '' : currentPackage && currentPackage.price}
                            handleChange={(value) => handleChangeValue("price", value)}
                            isDivider={true}
                            disableChar={true}
                          />

                          {isPointsErrorValidation ? <Error error={PRICE} /> :
                            isPointsLengthErrorValidation ? <Error error={PRICE_LENGTH} /> : ""}
                        </div>
                      </div>
                      <div className="package-price" id="price">
                  <div className="form-group form-custom" style={{
                    display: 'flex',
                    background: 'white',
                    justifyContent: 'space-between',
                    width: '89%',
                    height: '41px',
                    marginLeft: '14px',
                    marginBottom: '0px',
                  }}>
                  <p style={{opacity:"0.5"}}>Add Discounted Rate *</p> 
                  <img src={PlusIcon} alt="addIcon" style={{cursor:"pointer"}}  onClick={handleAddRate}/></div>
              </div>
                      <div className="package-price" id="price">
                        <div className="form-group form-custom">
                          <h6>Rs</h6>
                          <Input
                            label="Discounted Rate"
                            value={priceList?.adults[0]?.adult1?priceList?.adults[0]?.adult1:currentPackage?.revised_price>0?currentPackage?.revised_price:""}
                            // handleChange={(value) => handleChangeValue("revised_price", value)}
                            disabled={true}
                            isDivider={true}
                            disableChar={true}
                          />
                          {isError.error && isError.name == "revised_price" ? <Error error={isError.msg} /> : ""}
                        </div>
                      </div>
                    </div> :
                    <div className="package-price" id="price">
                      <div className="form-group form-custom">
                        <h6>Rs</h6>
                        <Input
                          label="Price *"
                          value={isEmptyResultsArrayBool ? '' : currentPackage && currentPackage.price}
                          handleChange={(value) => handleChangeValue("price", value)}
                          isDivider={true}
                          disableChar={true}
                        />
                        {
                          priceErrorLabel && <Error error={priceErrorLabel} />
                        }
                      </div>
                    </div>
                  }
                </div>
                <div
                  style={{ height: "164px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{ alignItems: "flex-end" }}>
                    <label for="" style={{ marginLeft: "17px" }}>Note</label>
                    <p style={{ marginLeft: "17px", fontSize: "12px", fontWeight: "400", lineHeight: "14px", opacity: "0.4", color: "#000000" }}>{currentPackage.note != null ? currentPackage.note.trim().length : 0}/100</p>
                  </div>
                  
                  <textarea
                    style={{ height: "98px",resize: "none" }}
                    maxlength="100"
                    value={currentPackage.note !== null ? currentPackage.note : ""}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      handleChangeValue("note", value)
                    }
                  />
                </div>
                
                <div
                  style={{ height: "164px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{ alignItems: "flex-end" }}>
                    <label for="" style={{ marginLeft: "17px" }}>Meta Title</label>
                    <p style={{ marginLeft: "17px", fontSize: "12px", fontWeight: "400", lineHeight: "14px", opacity: "0.4", color: "#000000" }}>{metaInfo.meta_title != null ? metaInfo.meta_title.trim().length : 0}/80</p>
                  </div>
                  <textarea
                    style={{ height: "98px",resize: "none" }}
                    resize= "none"
                    maxlength="80"

                    value={metaInfo.meta_title}
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      setMetaInfo(prevMetaInfo => ({
                        ...prevMetaInfo,
                        meta_title: e.target.value
                      }))
                    }
                  />
                </div>
                <div
                  style={{ height: "164px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{ alignItems: "flex-end" }}>
                    <label for="" style={{ marginLeft: "17px" }}>Meta Discription</label>
                    <p style={{ marginLeft: "17px", fontSize: "12px", fontWeight: "400", lineHeight: "14px", opacity: "0.4", color: "#000000" }}>{metaInfo?.meta_description != null ? metaInfo?.meta_description.trim().length : 0}/180</p>
                  </div>
                  <textarea
                    style={{ height: "98px",resize: "none" }}
                    resize= "none"
                    maxlength="180"
                    value={metaInfo.meta_description}

                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      setMetaInfo(prevMetaInfo => ({
                        ...prevMetaInfo,
                        meta_description: e.target.value
                      }))
                    }
                  />
                </div>
                {/* <div
                  style={{ height: "100px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{ alignItems: "flex-end" }}>
                    <label for="" style={{ marginLeft: "17px" }}>Product Code</label>
                    <p style={{ marginLeft: "17px", fontSize: "12px", fontWeight: "400", lineHeight: "14px", opacity: "0.4", color: "#000000" }}>{currentPackage?.edenred_product_code != null ? currentPackage?.edenred_product_code.trim().length : 0}/30</p>
                  </div>
                  <textarea
                    style={{ height: "40px",resize: "none" }}
                    resize= "none"
                    maxlength="30"
                    value={currentPackage?.edenred_product_code}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      handleChangeValue("edenred_product_code", value)
                    }
                  />
                </div> */}
              
          
                
                {/* } */}
                <div style={{ flex: 0.5, height: "fit-content", paddingBottom: "20px" }} className="form-group form-custom" id="brief">
                  <label for="">Brief Description *</label>
                  <textarea
                    style={{ height: '145px' }}
                    value={isEmptyResultsArrayBool ? '' : currentPackage?.brief_description}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      handleChangeValue("brief_description", value)
                    }
                  />
                  {isBriefDescriptionErrorValidation ?
                    <p style={{ color: "red" }}>Please enter brief description</p> :
                    isisBriefDescriptionLengthErrorValidation ?
                      <p style={{ color: "red" }}> Brief description should be less than 300 characters</p>
                      : ""}
                </div>
                {currentPackage?.package_type &&
                  (<div className="form-group form-custom" id="groupSize">
                    <label for="">{currentPackage?.package_type == "staybased" ? "Guests No’s" : "Group Size"}</label>
                    <MinMaxInput
                      state={currentPackage}
                      disableChar={true}
                      label="Guests No."
                      placeholder1="Min"
                      placeholder2="Max"
                      setState={setCurrentPackage}
                      setIsError={setIsError}
                    />
                    {isError.error && isError.name == "group_size" ? <Error error={isError.msg} /> : ""}
                  </div>)}
                {currentPackage.package_type == "staybased" &&
                  (<div className="toggle-container" style={{ marginLeft: 10 }}>
                    <ToggleGroup
                      setState={setCurrentPackage}
                      state={currentPackage}
                      options={currentPackage && currentPackage?.hotel_resort_facilities ? currentPackage?.hotel_resort_facilities : state.hotel_resort_facilities}
                      label="Hotel/ Resort Facilities"
                      optionsKey="hotel_resort_facilities"
                    />
                  </div>)}

                {/* COLUMN 1 LAST DIV */}
              </div>
              {/* COLUMN 2 STARTS */}

              <div style={{ flexDirection: 'column', paddingBottom: '10px', width: "100%" }}>
                {data?.section?.name !== "International" && (
                  // <div>
                  <div
                    className="form-group form-custom"
                    style={{ flex: 1, marginLeft: 10 }}
                  >
                    <label for="">{(apiGiftVoucher) ? 'Redeem at' : 'Location *'}</label>
                    <div className="location-field" id="location">
                      <textarea
                        style={{ height: 'auto' }}
                        className="form-control"
                        type="text"
                        rows="3"
                        cols="40"
                        value={isEmptyResultsArrayBool ? '' : currentPackage.length === 0 ? [] : currentPackage?.location && currentPackage?.location[0]?.address}
                        disabled
                      />
                      <div className="pin-icon">
                        <img
                          className="cursor-pointer"
                          src={PinLocation}
                          alt=""
                          onClick={() => {
                            setLocationSelect(true);
                            setIsEdit(true);
                          }}
                        />
                      </div>
                    </div>
                    {isLocationErrorValidation === true && <p style={{ color: "red" }}>Plesase enter location</p>}
                    {locationSelect && (
                      <GoogleMapModal
                        open={locationSelect}
                        edit={isEdit}
                        toggleModal={setLocationSelect}
                        handleCloseDone={() => setLocationSelect(false)}
                        editCurrentLocation={setLocation}
                        currentLocation={currentPackage?.location}
                      />
                    )}
                  </div>
                  // </div>
                )}
                <SecondLocation currentPackage={currentPackage} setCurrentPackage={setCurrentPackage} isInternational={data?.section?.name === "International"} />
                <div>
                  {/* {data?.section?.name !== "International" ? */}
                  <div
                    style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                    className="form-group form-custom" id="locationDropdown"
                  >
                    {/* <label for=""> City of Location </label> */}
                    <label for="">{CityAndCountryLabel}</label>
                    <Autocomplete
                      options={data?.section?.name === "International" ? countryList : getLocationdropDownList}
                      city={currentPackage?.location_city}
                      locationCityval={data?.section?.name === "International" ? currentPackage?.location_country : currentPackage?.location_city}
                      postLocationCityData={(data) => postLocationCityData(data)}
                    />
                    {isError.error && isError.name == "location_error" ? <Error error={isError.msg} /> : ""}
                  </div>
                  {currentPackage?.package_type == "staybased" &&
                    (<div className="form-group form-custom" style={{ marginLeft: "10px" }} id="locationType">
                      <Input
                        label="Location Type *"
                        value={currentPackage?.location_type}
                        handleChange={(val) => handleChangeValue("location_type", val)}
                      />
                      {isError.error && isError.name == "location_type" ? <Error error={isError.msg} /> : ""}
                    </div>)}
                  {/* : ( */}
                  {/* <div
                        style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                        className="form-group form-custom"
                      >
                        <label for=""> Select Country </label>
                        <input
                          value={isEmptyResultsArrayBool ? "" : currentPackage.location_country}
                          // disabled={currentPackage.flexi_schedule}
                          type="text"
                          className="form-control"

                        />
                        <img
                          src={ArrowDown}
                          style={{
                            position: "absolute",
                            right: "30px",
                            top: "60px",
                          }}
                        />
                      </div> */}
                  {/* ) */}
                  {/* } */}
                </div>
                {/* {data?.section?.name !== "International" && ( */}
                {!isItinerary &&
                  (<div
                    style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                    className="form-group form-custom" id="schedule"
                  >
                    <div className="d-flex" style={{ background: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.375rem 0', lineHeight: '1.5', fontWeight: '400', color: '#495057', fontSize: '1rem', marginBottom: 10 }}>
                      <div className="col-md-9" >Flexible dates</div>
                      <div className="col" style={{ textAlign: 'right' }}>
                        <PurpleSwitch
                          checked={currentPackage?.flexi_schedule}
                          size="small"
                          color="#2196F3"
                          name="flexi_schedule"
                          onChange={handleFlexiSchedule}
                        />
                      </div>
                    </div>
                    <label for="">Schedule * </label>
                    <input
                      value={isEmptyResultsArrayBool ? "" : resData}
                      disabled={currentPackage.flexi_schedule}
                      type="text"
                      className="form-control"
                      id="schedule"
                    />
                    {isScheduleErrorValidation && <p style={{ color: "red" }}>Please select schedule</p>}
                    <img
                      src={Edit}
                      style={{ position: "absolute", right: "20px", top: "105px", padding: "0px 10px", background: "#fff" }}
                      onClick={() => { !currentPackage.flexi_schedule && setPopupSelect(true) }}
                    />
                  </div>)}

                  {/* blocked_dates */}
                  
                  <div
                    style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                    className="form-group form-custom" id="schedule"
                  >
                    <div className="d-flex" style={{ background: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.375rem 0', lineHeight: '1.5', fontWeight: '400', color: '#495057', fontSize: '1rem', marginBottom: 10 }}>
                      <div className="col-md-9" >Next Day Booking</div>
                      <div className="col" style={{ textAlign: 'right' }}>
                        <PurpleSwitch
                         size="small"
                         checked={currentPackage && currentPackage?.disable_tomorrows_bookings}
                          color="#2196F3"
                          name="flexi_schedule"
                          onChange={(event)=>handleBlockTommorow(event)}

                        />
                     
                      </div>
                    </div>
                   
                    <label for="">Block Date Range </label>
                    <input
                      value={block_date_range ? blockDates_Range:""}
                      disabled={currentPackage?.restricted_booking_dates}
                      type="text"
                      className="form-control"
                      id="schedule"
                    />
                  
                    <img
                      src={Edit}
                      style={{ position: "absolute", right: "20px", top: "105px", padding: "0px 10px", }}
                      onClick={() => { setPopupDateBLock(true) }}
                    />
                  </div>

                  {popupDateBlock && (
                  <BlockDatePopup
                    openPopup={popupDateBlock}
                    closeModal={setPopupDateBLock}
                    // updatePopupData={updateDateDataBlockDates}
                    saveDateData={saveDateDataBlockDates}
                    isHolidayListing={true}
                    data={currentPackage}
                    popupDateBlock={popupDateBlock}
                    
                  />
                )}
                  {/* blocked_dates */}
                {popupSelect && (
                  <Popup
                    openPopup={popupSelect}
                    closeModal={setPopupSelect}
                    updatePopupData={setSchedules}
                    saveDateData={saveDateData}
                    isHolidayListing={true}
                    data={currentPackage}
                  />
                )}
                {/* ) */}
                {/* } */}
                <div
                  style={{ marginLeft: 10 }}
                  className="form-group form-custom" id="validTill"
                >
                  <div>
                    <label for="">Valid till *</label>
                    <input
                      value={isEmptyResultsArrayBool ? '' : currentPackage && currentPackage.valid_till}
                      type="date"
                      id="valid_till"
                      min={new Date().toISOString().split('T')[0]}
                      className="form-control"
                      onChange={(value) =>
                        handleChangeValue("valid_till", value)
                      }
                      disabled={currentPackage?.schedule_type == "date_range"}
                    />
                    {isValidTillErrorValidation && <p style={{ color: "red" }}>Please select date</p>}
                  </div>
                </div>
                {/* {data?.section?.name !== "International" &&  */}
                <div
                  style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                  className="form-group form-custom"
                >
                  <div className="d-flex" style={{ background: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.375rem 0', lineHeight: '1.5', fontWeight: '400', color: '#495057', fontSize: '1rem' }}>
                    <div className="col-md-9" >Enable Payment </div>
                    <div className="col" style={{ textAlign: 'right' }}>
                      <PurpleSwitch
                        checked={currentPackage && currentPackage.enable_payment}
                        size="small"
                        color="#2196F3"
                        name="enable_payment"
                        onChange={handleEnablePayment}
                      />
                    </div>
                  </div>


                </div>
                {currentPackage?.package_type == "itinerary" &&
                  (<div className="form-group form-custom" style={{ marginLeft: "10px" }} id="itineraryFields">
                    <label >Activity Type *</label>
                    <Dropdown
                      options={ACTIVITY_OPTIONS}
                      setState={setCurrentPackage}
                      state={currentPackage}
                      label="activity_type"
                      value={isEmptyResultsArrayBool ? '' : currentPackage && getActivityTypeVal(currentPackage.activity_type, ACTIVITY_OPTIONS)}
                      setIsError={setIsError}
                    />
                    {isError.error && isError.name == "activity_type" ? <Error error={isError.msg} /> : ""}
                    <label className="mt-3">Activity Level *</label>
                    <Dropdown
                      options={ACTIVITY_LEVEL_OPTIONS}
                      setState={setCurrentPackage}
                      state={currentPackage}
                      label="activity_level"
                      value={isEmptyResultsArrayBool ? '' : currentPackage && ACTIVITY_LEVEL_VALUE(currentPackage?.activity_level)}
                      setIsError={setIsError}
                    />
                    {isError.error && isError.name == "activity_level" ? <Error error={isError.msg} /> : ""}
                    <div style={{ marginTop: "10px" }}>
                      <Input
                        label="Ages *"
                        value={isEmptyResultsArrayBool ? '' : currentPackage && currentPackage.ages}
                        // disableChar={true}
                        handleChange={(val) => handleChangeValue("ages", val)}
                      />
                      {isError.error && isError.name == "ages" ? <Error error={isError.msg} /> : ""}
                    </div>
                  </div>)}
                {/* } */}
                <div style={{ marginLeft: 10 }}
                  className="form-group toggle-container"
                >

                  <label for="" style={{ fontSize: "12px", opacity: 0.4, fontWeight: 400 }}>Highlighting</label>
                  <div style={{ display: "flex" }}>
                    <div style={{ backgroundColor: "white", height: "fit-content", borderRadius: "5px" }}>
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value="start"
                          control={
                            <PurpleSwitch
                              checked={
                                isEmptyResultsArrayBool ? "" : currentPackage && currentPackage.best_seller
                              }
                              size="small"
                              color="#2196F3"
                              name="best_seller"
                              onChange={handleBestSeller}
                            />
                          }
                          label="Bestseller"
                          labelPlacement="start"
                        />
                      </FormControl>
                    </div>
                    {data?.section?.name !== "Deals" && dropdownValue !== "Deals" &&
                      <div style={{ backgroundColor: "white", marginLeft: "10px", height: "fit-content", borderRadius: "5px", display: "none" }}>
                        <ToggleButton
                          label="Also a Deal?"
                          name="deal"
                          checkValue={isEmptyResultsArrayBool ? '' : currentPackage && currentPackage.deal}
                        // handleToggleChange={dealHandle}
                        />
                      </div>}
                  </div>
                  {data?.section?.name === "International" &&
                    <div className="toggle" style={{ backgroundColor: "white", height: "fit-content", borderRadius: "5px", marginTop: "5px" }}>
                      <ToggleButton
                        label={openingSoonLabel()}
                        name="opening_soon"
                        checkValue={currentPackage?.opening_soon}
                        handleToggleChange={(event) => openingSoonhandler(event)}
                      />
                      <OpeningSoonCalander
                        open={openingSoon}
                        closeHandle={() => setOpeningSoon(false)}
                        dateChange={dateChange}
                      />
                    </div>
                  }
                  <div className="toggle" style={{ backgroundColor: "white", height: "fit-content", borderRadius: "5px", marginTop: "5px" }}>
                    <ToggleButton
                      label="COVID Safe?"
                      name="covid_safe"
                      checkValue={isEmptyResultsArrayBool ? '' : currentPackage && currentPackage?.covid_safe}
                      handleToggleChange={covidSafeHandle}
                    />
                  </div>
                </div>
                {currentPackage.package_type == "staybased" &&
                  (<div className="toggle-container" id="amenities" style={{ marginLeft: 10 }}>
                    <ToggleGroup
                      setState={setCurrentPackage}
                      state={currentPackage}
                      options={currentPackage && currentPackage?.featured_amenities && currentPackage?.featured_amenities?.length > 0 ? currentPackage?.featured_amenities : state.featured_amenities}
                      label="Featured Amenities *"
                      optionsKey="featured_amenities"
                      setIsError={setIsError}
                    />
                    {isError?.error && isError?.name == "featured_amenities" ? <Error error={isError.msg} /> : ""}
                  </div>)}
                {currentPackage.package_type == "staybased" &&
                  (<div className="toggle-container" style={{ marginLeft: 10 }}>
                    <ToggleGroup
                      setState={setCurrentPackage}
                      state={currentPackage}
                      options={currentPackage && currentPackage?.room_amenities ? currentPackage?.room_amenities : state.room_amenities}
                      label="Room Amenities"
                      optionsKey="room_amenities"
                    />
                  </div>)}
                {/* COLUMN 2 LAST DIV */}
              </div>
            </div>

            <div className="form-group form-custom mt-3" id="desc">
              <Tabs defaultActiveKey="1" >
                <TabPane tab={`Description *`} key="1">
                  {!isNewPack(data?.created) ?
                    <JoditEditor
                      ref={editor}
                      value={isEmptyResultsArrayBool ? '' : currentPackage && getModifiedHtml(currentPackage?.description)}
                      config={configJoditEditor}
                      tabIndex={1}
                      onBlur={(newContent) => { setContent(newContent); setIsDescriptionErrorValidation(false) }}
                      onChange={(newContent) => { }}
                    /> :
                    <CkEditor
                      data={currentPackage?.description}
                      handleChange={(newContent) => { setContent(newContent); setIsDescriptionErrorValidation(false) }}
                    />}
                  {isDescriptionErrorValidation && <p style={{ color: "red" }}>Please add some description</p>}

                </TabPane>
                {currentPackage?.package_type == "itinerary" && (<>
                  <TabPane tab={`Itinerary`} key="2">
                    {!isNewPack(data?.created) ?
                      <JoditEditor
                        ref={editor}
                        value={isEmptyResultsArrayBool ? '' : currentPackage && getModifiedHtml(currentPackage?.itinerary)}
                        config={configJoditEditor}
                        tabIndex={1}
                        onBlur={(newContent) => setCurrentPackage({ ...currentPackage, itinerary: newContent })}
                        onChange={(newContent) => { }}
                      /> :
                      <CkEditor
                        data={currentPackage?.itinerary}
                        handleChange={(newContent) => setCurrentPackage({ ...currentPackage, itinerary: newContent })}
                      />
                    }
                  </TabPane>
                  <TabPane tab={`Accomodations`} key="3">
                    {!isNewPack(data?.created) ?
                      <JoditEditor
                        ref={editor}
                        value={isEmptyResultsArrayBool ? '' : currentPackage && getModifiedHtml(currentPackage?.accomodations)}
                        config={configJoditEditor}
                        tabIndex={1}
                        onBlur={(newContent) => setCurrentPackage({ ...currentPackage, accomodations: newContent })}
                        onChange={(newContent) => { }}
                      /> :
                      <CkEditor
                        data={currentPackage?.accomodations}
                        handleChange={(newContent) => setCurrentPackage({ ...currentPackage, accomodations: newContent })}
                      />
                    }
                  </TabPane>
                  <TabPane tab={`Essential Info`} key="4">
                    {!isNewPack(data?.created) ?
                      <JoditEditor
                        ref={editor}
                        value={isEmptyResultsArrayBool ? '' : currentPackage && getModifiedHtml(currentPackage?.essential_info)}
                        config={configJoditEditor}
                        tabIndex={1}
                        onBlur={(newContent) => setCurrentPackage({ ...currentPackage, essential_info: newContent })}
                        onChange={(newContent) => { }}
                      /> :
                      <CkEditor
                        data={currentPackage?.essential_info}
                        handleChange={(newContent) => setCurrentPackage({ ...currentPackage, essential_info: newContent })}
                      />
                    }
                  </TabPane>
                  <TabPane tab={`Schedule *`} key="5">
                    <ScheduleBoxItinerary
                      setPopupSelect={setPopupSelect}
                      state={currentPackage}
                      isEdit={true}
                    />

                  </TabPane>
                  <TabPane tab={`FAQ’s`} key="6">
                    {!isNewPack(data?.created) ?
                      <JoditEditor
                        ref={editor}
                        value={isEmptyResultsArrayBool ? '' : currentPackage && getModifiedHtml(currentPackage?.faq)}
                        config={configJoditEditor}
                        tabIndex={1}
                        onBlur={(newContent) => setCurrentPackage({ ...currentPackage, faq: newContent })}
                        onChange={(newContent) => { }}
                      /> :
                      <CkEditor
                        data={currentPackage?.faq}
                        handleChange={(newContent) => setCurrentPackage({ ...currentPackage, faq: newContent })}
                      />
                    }
                  </TabPane>

                </>
                )}
              </Tabs>
              {isScheduleErrorValidation && currentPackage?.package_type == "itinerary" && <p style={{ color: "red" }}>Please select schedule</p>}
            </div>
          </div>
        </form>
        <div className="form-group form-custom mt-3">
          <Testimonial module="holiday" id={currentPackage?.holiday} cid={currentPackage?.id} />
        </div>
        <div className="form-group form-custom mt-3">
          <Reviews module="holiday" id={currentPackage?.id} mainPackId={currentPackage?.holiday}
          />
        </div>
      </div>
      {addHandleRate&&<AddHandleRate 
                 open={addHandleRate}
                 modalClose={() => setAddHandleRate(false)}
                 priceList={priceList}
                 setPriceList={setPriceList}
                 handleAddPrice={handleAddPrice}
                 isEdit={true}
                 priceDataLoad={priceDataLoad}
                 currentPackage={currentPackage}
                 setToDeleteIds={setToDeleteIds}
                 toDeleteIds={toDeleteIds}
            />}
      {imageSelect && (
        <ImageMultiSelect
          isUpdateEvent={true}
          isAddNewEvent={false}
          title={currentPackage.pack_title}
          city={currentPackage?.location?.city}
          images={currentImages || []}
          open={imageSelect}
          toggleModal={setImageSelect}
          handleCloseDone={() => setImageSelect(false)}
          handleUpdatePackageImage={handleUpdatePackageImage}
          moduleType="holidays"
          sectionName={data.section.name}
        />
      )}
    </div>
  );
};
