import React, { useState, useRef, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import Edit from "../../../assets/images/edit.svg";
import PinLocation from "../../../assets/images/pin.svg";
import { useSelector, useDispatch } from "react-redux";
import SchedulePopUp from "./addNewSchedulePopUp";
import { FaFileUpload } from "react-icons/fa";
import JoditEditor from "jodit-react";
// import ImageMultiSelect from "./imageMultiSelect/imageMultiSelect";
import ImageMultiSelect from "../../../common/imageMultiSelect/imageMultiSelect";
import GoogleMapModal from "../../utilities/GoogleMap/MapModal";
import PlusIcon from "../../../assets/images/plusIconSquare.svg";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDown from "../../../assets/images/arrowDown.svg";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import DefaultImage from "../../../assets/images/plain-background.jpeg";
import "../../../assets/css/partials/addCorpPackageDetails.scss";
import "../../../assets/css/partials/packageDetails.scss";
import "../../../assets/css/partials/layout.scss";
import * as experiencesListingAction from "../../../actions/experienceListingAction";
import * as holidayListingAction from "../../../actions/holidaysAction";
import * as bookingAction from "../../../actions/bookingAction";
import { useSnackbar } from "notistack";
import moment from "moment";
import FeaturedPackage from "../../../common/featuredPackage";
import Autocomplete from "../../../common/autoComplete";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dropdown from "../../../common/uiElements/dropdown";
import Error from "../../../common/error";
import ToggleGroup from "../../../common/uiElements/toggelGroup";
import MinMaxInput from "../../../common/uiElements/minMaxInput";
import ScheduleBoxItinerary from "../../holidays/listings/scheduleBoxItinerary";
import PackTypeButtons from "../../../common/uiElements/packTypeButtons";
import Input from "../../../common/uiElements/input";
import BlockDatePopup from "../../popup/blockDatePopup";
import AddHandleRate from "../../../common/addRateModel";
import { Tabs } from "antd";
import {
  SECTION_NAME,
  PACKAGE_TITLE_EMPTY_ERROR_VALIDATION,
  PACKAGE_TITLE_LENGTH_ERROR_VALIDATION,
  IMAGE,
  LOCATION_EMPTY,
  LOCATION_EMPTY_LENGTH,
  PRICE,
  PRICE_LENGTH,
  SCHEDULE,
  BRIEF_DESCRIPTION_EMPTY,
  BRIEF_DESCRIPTION_EMPTY_LENGTH,
  VALID_TILL,
  DESCRIPTION,
  THUMBNAIL_IMAGE_ERROR,
  REVISED_PRICE,
  REVISED_PRICE_LENGTH,
  GUEST_EMPTY,
  LOCATION_TYPE_EMPTY,
  ACTIVITY_TYPE_EMPTY,
  ACTIVITY_LEVEL_EMPTY,
  AGES_EMPTY,
  ITINERARY_EMPTY,
  ACCOMODATION_EMPTY,
  ESSENTIAL_INFO_EMPTY,
  FAQ,
  REVISED_PRICE_ERROR,
  GUEST_ERROR,
  LOCATION_TYPE_CHAR_LIMIT,
  FEATURED_AMENITIES_ERROR,
  COUNTRY_ERROR,
  CITY_ERROR
} from "../../../utils/constants";
import {
  scheduleDaysVal,
  scheduleNightsVal,
  scheduleHoursVal,
  scheduleMinutesVal,
  checkFeaturedAmenitiesLength,
  focusInput,
  ACTIVITY_LEVEL_VALUE,
} from "../../../utils/index";
import {FEATURED_AMENITIES_LIST, ROOM_AMENITIES_LIST, ACTIVITY_OPTIONS, ACTIVITY_LEVEL_OPTIONS, RESORT_FACILITIES_LIST} from '../../../resources/packageData'
import SecondLocation from "../../../common/secondLocation";
import CkEditor from '../../../common/ckEditor/ckeditor'
import Meta_Input from "../../../common/uiElements/meta_Input";

const { TabPane } = Tabs;
const PurpleSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#2196F3",
    },
    "&$checked + $track": {
      backgroundColor: "#2196F3",
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

let scheduledData = null;

export default function AddExperiencesPackageDetails() {
  const [imageSelect, setImageSelect] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [locationSelect, setLocationSelect] = useState(false);
  const [scheduleType, setScheduleType] = useState("");
  const [popupSelect, setPopupSelect] = useState(false);
  const[addHandleRate,setAddHandleRate]=useState(false)
  const [blockDates_Range,setBlockDates_range]=useState()
  const [popupDateBlock,setPopupDateBLock]=useState(false)
  const[block_date_range,setBlock_date_range]=useState()
  const [content, setContent] = useState("");
  const [select, setSelect] = React.useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isGiftVoucher, setIsGiftVoucher] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const[revisePrice,setRevisedPrice]=useState()
  const [symbolValidation,setSymbolValidation]=useState(false);
  const[symbolErrorMsg,setSymbolErrorMsg]=useState()

  const [
    isExperienceAddSecnameErrorValidation,
    setIsExperienceAddSecnameErrorValidation,
  ] = useState(null);
  const [
    isExperienceAddPackTitleEmptyErrorValidation,
    setIsExperienceAddPackTitleEmptyErrorValidation,
  ] = useState(null);
  const [
    isExperienceAddPackTitleLengthErrorValidation,
    setIsExperienceAddPackTitleLengthErrorValidation,
  ] = useState(null);
  const [
    isExperienceAddImgErrorValidation,
    setIsExperienceAddImgErrorValidation,
  ] = useState(null);
  const [
    isExperienceAddImgThumbnailErrorValidation,
    setIsExperienceAddImgThumbnailErrorValidation,
  ] = useState(null);
  const [
    isExperienceAddPointsEmptyErrorValidation,
    setIsExperienceAddPointsEmptyErrorValidation,
  ] = useState(null);
  const [
    isExperienceAddPointsLengthErrorValidation,
    setIsExperienceAddPointsLengthErrorValidation,
  ] = useState(null);
  const [
    isexperienceAddBrigDescEmtyErrorValidation,
    setIsexperienceAddBrigDescEmtyErrorValidation,
  ] = useState(null);
  const [
    isexperienceAddBrigDescLengthErrorValidation,
    setIsexperienceAddBrigDescLengthErrorValidation,
  ] = useState(null);
  const [
    isexperienceAddLocationEmptyErrorvalidation,
    setIsexperienceAddLocationEmptyErrorvalidation,
  ] = useState(null);
  const [
    isexperienceAddLocationLengthErrorvalidation,
    setIsexperienceAddLocationLengthErrorvalidation,
  ] = useState(null);
  const [
    isexperienceAddScheduleErrorValidation,
    setIsexperienceAddScheduleErrorValidation,
  ] = useState(null);
  const [
    isexperienceAddValidTillErrorValidation,
    setIsexperienceAddValidTillErrorValidation,
  ] = useState(null);
  const [isFeaturedErrorValidation, setIsFeaturedErrorValidation] = useState(
    false
  );
  const [
    isexperienceAddDesErrorValidation,
    setIsexperienceAddDesErrorValidation,
  ] = useState(null);
  const [isError, setIsError] = useState({ error: false, msg: "", name: "" })

  const expListingDropDownVal = useSelector(
    (state) => state.experiencesListing.experienceListingDropDownvalue
  );
  const pageID = useSelector((state) => state.experiencesListing.pageId);
  const addImage = useSelector(
    (state) => state.experiencesListing.allExperiencesImagesData
  );
  const selectedMenuDropdown = useSelector(
    (state) => state.experiencesListing?.selectedDropdown
  );
  const imagesWithoutId = addImage;
  const dispatch = useDispatch();
  const editor = useRef(null);

  const getLocationdropDownList = useSelector(
    (state) => state.holidayListing.dropDownLocationlist
  );
  const [priceList, setPriceList] = useState({
    adults: [{adult1:null}],
    children: [{child1:null}]
  });

const handleAddPrice=()=>{

  const allValuesUpdated = Object.values(priceList).every(categoryArray =>
    categoryArray.every(item =>
      Object.values(item).every(value => {
        const isValid = value !== null && !isNaN(value);
        return isValid;
      })
    )
  );
  

if (!allValuesUpdated) {
  enqueueSnackbar("Please fill all the fields", {
    variant: "error",
  });
} else {
  setRevisedPrice(priceList?.adults[0]?.adult1)
  setAddHandleRate(false);
}
  
}
  console.log({ getLocationdropDownList });
  const postLocationCityData = (data) => {
    setState({
      ...state,
      location_city: data,
    });
    setIsError({error: false, name: "", msg: ""})
  };
  // useEffect(() => {
  //   dispatch(experiencesListingAction.getExperienceListing());
  // }, []);

  const handleClickSelect = (event) => {
    dispatch(experiencesListingAction.selectDropdown());
    setSelect(event.currentTarget);
  };
  const handleBlockTommorow=(event)=>{
  
    if (event.target.checked === true) {
   
   setState({
    ...state,
    disable_tomorrows_bookings: true
   })
  }
else{

 setState({
  ...state,
  disable_tomorrows_bookings: false
 })

}
  }

  const handleAddRate=()=>{
    setAddHandleRate(true)
  }

  const handleFlexiSchedule = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
      schedule_data: event.target.checked ? {} : state?.schedule_data,
      schedule_type: event.target.checked ? "" : state?.schedule_type,
    });
    setIsexperienceAddScheduleErrorValidation(false)
  };

  const handleCloseSelect = (item) => {
    setDropdownValue(item);
    setSelect(null);
    if (item?.gift_voucher === true) {
      setIsGiftVoucher(true);
    } else {
      setIsGiftVoucher(false);
    }
    setIsExperienceAddSecnameErrorValidation(false) 
  };

  const [state, setState] = useState({
    package_type: "staybased",
    pack_title: "",
    meta_description:"",
    meta_title:"",
    meta_description:"",
    edenred_product_code:"",
    disable_tomorrows_bookings:false,
    restricted_booking_dates:null,
    note: null,
    images: [],
    location: [],
    schedule_type: "",
    schedule_data: {},
    valid_till: "",
    price: "",
    best_seller: false,
    covid_safe: false,
    description: "",
    featured: false,
    flexi_schedule: false,
    enable_payment: false,
    location_city: "",
    location_country: "",
    revised_price: "",
    location_type: "",
    group_size: {min: "", max: ""},
    ages: "",
    activity_type: "",
    activity_level: "",
    itinerary: "",
    accomodations: "",
    essential_info: "",
    faq: "",
    featured_amenities: JSON.parse(JSON.stringify(FEATURED_AMENITIES_LIST)),
    room_amenities: JSON.parse(JSON.stringify(ROOM_AMENITIES_LIST)),
    hotel_resort_facilities: JSON.parse(JSON.stringify(RESORT_FACILITIES_LIST))

  });

  useEffect(() => {
    dispatch(holidayListingAction.getDropDownLocationList());
  }, []);

  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    setIsError({ error:false, msg:'', name: "" })
    setIsExperienceAddPackTitleEmptyErrorValidation(false)
    setIsExperienceAddPackTitleLengthErrorValidation(false)
  };

  const meta_title_change=(key, value)=>{
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    setIsError({ error:false, msg:'', name: "" })
    setIsExperienceAddPackTitleEmptyErrorValidation(false)
    setIsExperienceAddPackTitleLengthErrorValidation(false)

  }
  const meta_description=(key, value)=>{
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    setIsError({ error:false, msg:'', name: "" })
    setIsExperienceAddPackTitleEmptyErrorValidation(false)
    setIsExperienceAddPackTitleLengthErrorValidation(false)

  }
  const priceHandler = (key, e) => {
    setState(() => ({
      ...state,
      [key]: e.target.value,
    }));
    setIsError({ error:false, msg:'', name: "" })
    setIsExperienceAddPointsEmptyErrorValidation(false)
    setIsExperienceAddPointsLengthErrorValidation(false)
  };
  const loader = (id) => {
    console.log("loader", expListingDropDownVal);
    dispatch(
      experiencesListingAction.getExperienceListing(
        pageID,
        expListingDropDownVal?.id
      )
    );
    setIsLoading(false);
    dispatch(experiencesListingAction.editExperienceListItem(id));
  };
  const briefDescriptionHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    setIsexperienceAddBrigDescEmtyErrorValidation(false)
    setIsexperienceAddBrigDescLengthErrorValidation(false)
  };
  const noteHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };

  const validTillHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    setIsexperienceAddValidTillErrorValidation(false)
  };

  const handleError = (errorMsg) => {
    setIsFeaturedErrorValidation(errorMsg);
  };

  const dateSheduleHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };

  function checkSentence(title) {
    const allowedRegex = /[@#*()\-\_,]/;
    const notAllowedRegex = /[~`!$%^&+=\{\}\[\]|\\/:;"‘<>.?]/;

    for (let i = 0; i < title.length; i++) {
        const char = title.charAt(i);
     if (notAllowedRegex.test(char)) {
      setSymbolValidation(true)
      setSymbolErrorMsg(`${char} -> now allowed in title please remove !`)
    
      enqueueSnackbar(`${char} -> now allowed in title please remove !`, {
        variant: "error",
    });

           return 
        } 
        else{
          setSymbolValidation(false)
          setSymbolErrorMsg(false)

        }
    }
}

  

  const newDataSubmitHandler = (e) => {
    e.preventDefault();
    checkSentence(state.pack_title)
    if(symbolValidation){ 
    
                              setSymbolValidation(true)
                              return
   }

    setIsExperienceAddSecnameErrorValidation(false);
    setIsExperienceAddPackTitleEmptyErrorValidation(false);
    setIsExperienceAddPackTitleLengthErrorValidation(false);
    setIsExperienceAddImgErrorValidation(false);
    setIsExperienceAddImgThumbnailErrorValidation(false);
    setIsExperienceAddPointsEmptyErrorValidation(false);
    setIsExperienceAddPointsLengthErrorValidation(false);
    setIsexperienceAddBrigDescEmtyErrorValidation(false);
    setIsexperienceAddBrigDescLengthErrorValidation(false);
    setIsexperienceAddLocationEmptyErrorvalidation(false);
    setIsexperienceAddLocationLengthErrorvalidation(false);
    setIsexperienceAddScheduleErrorValidation(false);
    setIsexperienceAddValidTillErrorValidation(false);
    setIsexperienceAddDesErrorValidation(false);
    let finalImages = addImage.map((item, index) => {
      let sImg = {
        image: item.image,
        video: item.video,
        thumbnail: item.thumbnail,
        priority: index + 1,
        media_type: item.media_type,
        alt_text:item.alt_txt||"",
        title_text:item.title_txt||""
      };

      return sImg;
    });
    const addNewData = {
      section: dropdownValue?.id,
      package_type: state.package_type,
      pack_title: state.pack_title.trim().replace(/\s+/g, ' '),
      meta_info : {"meta_title":state.meta_title,
      "meta_description":state.meta_description
      },
      edenred_product_code:state.edenred_product_code,
      brief_description: state.brief_description,
      disable_tomorrows_bookings:state?.disable_tomorrows_bookings,
      restricted_booking_dates:state?.restricted_booking_dates,
      note: state.note,
      images: finalImages,
      location: state.location,
      schedule_type: isGiftVoucher ? null : state.schedule_type,
      schedule_data: isGiftVoucher ? null : state.schedule_data,
      valid_till: state.valid_till,
      price: state.price,
      best_seller: state.best_seller,
      covid_safe: state.covid_safe,
      featured: state.featured,
      flexi_schedule: state.flexi_schedule,
      description: content,
      enable_payment: state.enable_payment,
      location_city: state.location_city,
      location_country: state.location_country,
      revised_price: state.revised_price ? state.revised_price : 0,
      location_type: state.location_type,
      group_size: state.group_size,
      ages: state.ages,
      activity_type: state.activity_type,
      activity_level: state.activity_level,
      itinerary: state.itinerary,
      accomodations: state.accomodations,
      essential_info: state.essential_info,
      faq: state.faq,
      featured_amenities: state.featured_amenities,
      room_amenities: state.room_amenities,
      hotel_resort_facilities: state.hotel_resort_facilities,
      price_list:[
        ...priceList?.adults?.map((elem,index)=>{
          return { "age_group":"adult", "num_people":parseInt(`${index+1}`),"price":elem['adult' + (index + 1)]}
        }),
        ...priceList?.children?.map((elem,index)=>{
          return { "age_group":"child", "num_people":parseInt(`${index+1}`),"price":elem['child' + (index + 1)]}
        }),
        // {"age_group":"adult", "num_people":1, "price": parseInt(priceList?.adults?.adult1)}, {"age_group":"adult", "num_people":2, "price": parseInt(priceList?.adults?.adult2)},
        // {"age_group":"adult", "num_people":3, "price": parseInt(priceList?.adults?.adult3)}, {"age_group":"adult", "num_people":4, "price": parseInt(priceList?.adults?.adult4)},
        // {"age_group":"adult", "num_people":5, "price": parseInt(priceList?.adults?.adult5)}, {"age_group":"adult", "num_people":6, "price": parseInt(priceList?.adults?.adult6)},
        // {"age_group":"adult", "num_people":7, "price": parseInt(priceList?.adults?.adult7)}, {"age_group":"adult", "num_people":8, "price": parseInt(priceList?.adults?.adult8)},
        // {"age_group":"adult", "num_people":9, "price": parseInt(priceList?.adults?.adult9)}, {"age_group":"adult", "num_people":10, "price": parseInt(priceList?.adults?.adult10)},
        // {"age_group":"child", "num_people":1, "price": parseInt(priceList?.children?.child1)}, {"age_group":"child", "num_people":2, "price": parseInt(priceList?.children?.child2)},
        // {"age_group":"child", "num_people":3, "price": parseInt(priceList?.children?.child3)}, {"age_group":"child", "num_people":4, "price": parseInt(priceList?.children?.child4)},
        // {"age_group":"child", "num_people":5, "price": parseInt(priceList?.children?.child5)}, {"age_group":"child", "num_people":6, "price": parseInt(priceList?.children?.child6)},
        // {"age_group":"child", "num_people":7, "price": parseInt(priceList?.children?.child7)}, {"age_group":"child", "num_people":8, "price": parseInt(priceList?.children?.child8)},
        // {"age_group":"child", "num_people":9, "price": parseInt(priceList?.children?.child9)}, {"age_group":"child", "num_people":10, "price": parseInt(priceList?.children?.child10)}
    ]
    };
    const htmltoText = (html) => {
      let text = html;
      text = text.replace(/\n/gi, "");
      text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
      text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
      text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, "");
      text = text.replace(/<\/div>/gi, "");
      text = text.replace(/<\/li>/gi, "");
      text = text.replace(/<li.*?>/gi, "  *  ");
      text = text.replace(/<\/ul>/gi, "");
      text = text.replace(/<\/p>/gi, "");
      text = text.replace(/<br\s*[\/]?>/gi, "");
      text = text.replace(/<[^>]+>/gi, "");
      text = text.replace(/^\s*/gim, "");
      text = text.replace(/ ,/gi, "");
      text = text.replace(/ +/gi, "");
      text = text.replace(/\n+/gi, "");
      text = text.replace('&nbsp;', "");
      return text;
    };
  if(htmltoText(content).replaceAll(' ', '').trim().length===0){
    setIsexperienceAddDesErrorValidation(true);
    focusInput("#desc")
      return;
  }

    if (dropdownValue?.id === undefined || dropdownValue?.id === null)
      setIsExperienceAddSecnameErrorValidation(true);
    else if (state.pack_title === "" || state.pack_title === undefined)
      setIsExperienceAddPackTitleEmptyErrorValidation(true);
    else if (state.pack_title.length > 150)
      setIsExperienceAddPackTitleLengthErrorValidation(true);
    else if (imagesWithoutId.length === 0)
      setIsExperienceAddImgErrorValidation(true);
    else if (!imageThubnail)
    
      setIsExperienceAddImgThumbnailErrorValidation(true);
      else if(Object.values(priceList).every((category) =>
      Object.values(category).every((value) => value === null))){
        enqueueSnackbar("Please fill all the fields of add rate", {
          variant: "error",
      });
    }
    else if (state.price === "" || state.price === undefined)
      setIsExperienceAddPointsEmptyErrorValidation(true);
    else if (state.price.length > 6)
      setIsExperienceAddPointsLengthErrorValidation(true);
    // else if (priceList?.adults?.adult1.length == "") setIsError({error: true, name: "revised_price", msg: REVISED_PRICE})
    // else if (priceList?.adults?.adult1.length > 6) {
    //   setIsError({error: true, name: "revised_price", msg: REVISED_PRICE_LENGTH})
    //   focusInput("#price")
    // }
    else if(Number(state.price) <= Number(priceList?.adults[0]?.adult1)) {
      setIsError({ error:true, msg:REVISED_PRICE_ERROR, name: "revised_price" })
      focusInput("#price")
    }
    else if (
      state.brief_description === "" ||
      state.brief_description === undefined
    )
      {
        setIsexperienceAddBrigDescEmtyErrorValidation(true);
        focusInput("#brief")
      }
    else if (state.brief_description.length > 300){
      setIsexperienceAddBrigDescLengthErrorValidation(true);
      focusInput("#brief")
    }
      // else if ((state.group_size.min == "" || state.group_size.max == "") && state.package_type == "itinerary" ) setIsError({error: true, name: "guest_no", msg: GUEST_EMPTY})
    else if (Number(state.group_size.min) > Number(state.group_size.max) ) {
      setIsError({error: true, name: "guest_no", msg: GUEST_ERROR})
      focusInput("#groupSize")
    }
    else if (state.location === undefined || state.location.length === 0)
      setIsexperienceAddLocationEmptyErrorvalidation(true);
    else if (state.location.length > 300)
      setIsexperienceAddLocationLengthErrorvalidation(true);
    else if (!state?.location_city) {
      setIsError({error: true, name: "location_error", msg: CITY_ERROR});
      focusInput("#locationDropdown")
    }
    else if (
      !isGiftVoucher &&
      !state.flexi_schedule &&
      (!scheduledData || Object.entries(scheduledData).length === 0)
    )
      {setIsexperienceAddScheduleErrorValidation(true); focusInput(state.package_type == "itinerary" ? "#desc" :"#schedule")}
    else if (state.valid_till === "" || state.valid_till === undefined)
      {setIsexperienceAddValidTillErrorValidation(true); focusInput("#validTill")}
    else if (state.location_type == "" && state.package_type == "staybased") {
      setIsError({error: true, name: "location_type", msg: LOCATION_TYPE_EMPTY})
      focusInput("#locationType")
    }
    else if (state.location_type?.length > 20 && state.package_type == "staybased") {
      setIsError({error: true, name: "location_type", msg: LOCATION_TYPE_CHAR_LIMIT})
      focusInput("#locationType")
    }
    else if (state.activity_type == "" && state.package_type == "itinerary") {
      setIsError({error: true, name: "activity_type", msg: ACTIVITY_TYPE_EMPTY})
      focusInput("#itineraryFields")
    }
    else if (state.activity_level == "" && state.package_type == "itinerary") {
      setIsError({error: true, name: "activity_level", msg: ACTIVITY_LEVEL_EMPTY})
      focusInput("#itineraryFields")
    }
    else if (state.ages == "" && state.package_type == "itinerary") {
      setIsError({error: true, name: "ages", msg: AGES_EMPTY})
      focusInput("#itineraryFields")
    }
    else if (checkFeaturedAmenitiesLength(state.featured_amenities) && state.package_type == "staybased") {
      setIsError({error: true, name: "featured_amenities", msg: FEATURED_AMENITIES_ERROR})
      focusInput("#amenities")
    }
    // else if (content === "" || content === undefined)
    //   setIsexperienceAddDesErrorValidation(true);
    else {
      setIsLoading(true);
      dispatch(
        experiencesListingAction.handleAddNewData(
          addNewData,
          refreshData,
          handleError,
          loader
        )
      );
    }
  };
  const saveDateDataBlockDates = (date) => {
    if(date==null){
    
    
        setState({
          ...state,
          restricted_booking_dates:null
         })

    }
 
    else if(date){
    
    
        setState({
          ...state,
          restricted_booking_dates:{
            "selected_dates":date
          }
         })

    }
  
       setPopupDateBLock(false)


  };
  const handleCloseImage=()=>{
    setImageSelect(false)
    dispatch(experiencesListingAction.handleAllImages([]))
  }


  const refreshData = () => {
    setState({
      pack_title: "",
      brief_description: "",
      note: null,
      images: [],
      location: [],
      schedule_type: "",
      schedule_data: {},
      valid_till: "",
      price: "",
      best_seller: false,
      covid_safe: false,
      // featured: false,
      description: "",
      flexi_schedule: false,
      enable_payment: false,
      location_city: "",
      location_country: ""
    });
    //dispatch(experiencesListingAction.getExperienceListing());
    //dispatch(experiencesListingAction.setAddPackage(false));
    enqueueSnackbar("Package added successfully", {
      variant: "success",
    });
    setIsLoading(false);
    window.location.reload();
  };

  // all options from https://xdsoft.net/jodit/doc/
  const configJoditEditor = {
    readonly: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    placeholder: "Description...",
    controls: {
      font: false,
    },
    height: "500px"
  };

  const setSchedules = (days, nights, schedule_p) => {
    setState((prevstate) => {
      return {
        ...prevstate,
        schedule_data: { days: days, nights: nights },
        schedule_type: schedule_p,
      };
    });
    setPopupSelect(false);
    setScheduleType(schedule_p);
    setIsexperienceAddScheduleErrorValidation(false)
  };

  const formattedDays = (array) => {
    let weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return array
      .map((item) => {
        return weekDays[item];
      })
      .join();
  };

  const setRecurring = (selectedDays, hours, minutes, schedule_t) => {
    dispatch(experiencesListingAction.setSelectedDay([]));
    var newSelectedDyas = [];
    for (var i = 0; i < selectedDays.length; i++) {
      if (selectedDays[i] === "Sunday") {
        newSelectedDyas.push(0);
      } else if (selectedDays[i] === "Monday") {
        newSelectedDyas.push(1);
      } else if (selectedDays[i] === "Tuesday") {
        newSelectedDyas.push(2);
      } else if (selectedDays[i] === "Wednesday") {
        newSelectedDyas.push(3);
      } else if (selectedDays[i] === "Thursday") {
        newSelectedDyas.push(4);
      } else if (selectedDays[i] === "Friday") {
        newSelectedDyas.push(5);
      } else if (selectedDays[i] === "Saturday") {
        newSelectedDyas.push(6);
      }
    }
    setState((prevstate) => {
      return {
        ...prevstate,
        schedule_data: {
          newSelectedDyas: newSelectedDyas,
          recurringHours: hours,
          recurringMinutes: minutes,
        },
        schedule_type: schedule_t,
      };
    });
    setPopupSelect(false);
    setScheduleType(schedule_t);
    console.log("selectedDaysState", state);
  };

  const setSpecificDate = (multipleDates, hours, minutes, schedule_d) => {
    setState((prevstate) => {
      return {
        ...prevstate,
        schedule_data: {
          multipleDates: multipleDates,
          hours: hours,
          minutes: minutes,
        },
        schedule_type: schedule_d,
      };
    });
    setPopupSelect(false);
    setScheduleType(schedule_d);
    console.log("daterr", state.schedule_type);
  };

  const setDateRanges = (selectedDates, schedule_r) => {
    // selectedDates.forEach(function (arrayItem) {
    //   arrayItem.from = moment(arrayItem.from).format("DD-MM-YYYY");
    //   arrayItem.to = moment(arrayItem.to).format("DD-MM-YYYY");
    // });
    setState((prevstate) => {
      return {
        ...prevstate,
        schedule_data: { selectedDates: selectedDates },
        schedule_type: schedule_r,
      };
    });
    selectedDates.map((item, index) => {
      if(selectedDates.length == index + 1 ){
        setState(() => ({
          ...state,
          schedule_data: { selectedDates: selectedDates },
          schedule_type: schedule_r,
          "valid_till": moment(item.to, "DD-MM-YYYY").format("yyyy-MM-DD"),
        }));
      }
    })
    setPopupSelect(false);
    setScheduleType(schedule_r);
    console.log("rabgesss", state.schedule_type);
    setIsexperienceAddScheduleErrorValidation(false)
  };

  const setLocation = (address, latitude, longitude, city, state, country) => {
    setState((prevstate) => {
      return {
        ...prevstate,
        location: [
          {
            address: address,
            latitude: latitude,
            longitude: longitude,
            city: city,
            state: state,
            country: country
          },
        ],
        location_country: country,
      };
    });
    setIsexperienceAddLocationEmptyErrorvalidation(false)
    setIsexperienceAddLocationLengthErrorvalidation(false)
  };

  const handleBsetSeller = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleEnablePayment = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const covidSafeHandle = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleFeaturedpackage = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const handleUpdatePackageImage = (data) => {
    setState({
      ...state,
      images: data,
    });
    setIsExperienceAddImgErrorValidation(false)
    setIsExperienceAddImgThumbnailErrorValidation(false)
  };
  const imageThubnail = addImage.find((img) => img.thumbnail === true);

  if (state.schedule_type === "package" || scheduleType === "package") {
    scheduledData =
      scheduleDaysVal(state?.schedule_data?.days) +
      scheduleNightsVal(state?.schedule_data?.nights);
  } else if (
    state?.schedule_type === "date_range" ||
    scheduleType === "date_range"
  ) {
    scheduledData =
      state?.schedule_data.selectedDates &&
      state?.schedule_data.selectedDates !== null &&
      state?.schedule_data.selectedDates.map(
        (item) => `${item.from} to ${item.to}`
      );
  } else if (
    state?.schedule_type === "recurring" ||
    scheduleType === "recurring"
  ) {
    scheduledData =
      state?.schedule_data.newSelectedDyas &&
      state?.schedule_data.newSelectedDyas !== null &&
      formattedDays(state?.schedule_data?.newSelectedDyas) +
        " " +
        scheduleHoursVal(state?.schedule_data?.recurringHours) +
        scheduleMinutesVal(state?.schedule_data?.recurringMinutes);
  } else if (
    state?.schedule_type === "specific_dates" ||
    scheduleType === "specific_dates"
  ) {
    scheduledData =
      state?.schedule_data.multipleDates &&
      state?.schedule_data.multipleDates !== null &&
      state?.schedule_data.multipleDates.map(
        (item) => `${moment(item).format("Do MMMM")}`
      ) +
        scheduleHoursVal(state?.schedule_data?.hours) +
        scheduleMinutesVal(state?.schedule_data?.minutes);
  }else{
    scheduledData = ""
  }
  useEffect(()=>{
    const datesArray=state?.restricted_booking_dates?.selected_dates
         
         if(datesArray){
          const data=datesArray[0]?datesArray[0]:false
          const data1=datesArray[1]?datesArray[1]:false
          setBlock_date_range(`${data ? `${data.from} to ${data.to}` : ''}${data1 ? `,${data1.from}` : ''}`);
    
        }

  },[state])

  useEffect(()=>{
    dispatch(bookingAction.selectBlockDatesRestrictions(state))

  },[state])
  
  const handleInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <div>
      {isLoading && (
        <div style={{ marginLeft: 10, marginBottom: -3 }}>
          <LinearProgress />
        </div>
      )}
      <div className="packageDetails-holder">
        <form onSubmit={newDataSubmitHandler}>
          <div className="form-options">
            <div>
              <h2>Adding</h2>
            </div>
            <div className="form-btns d-flex align-items-center">
              <div style={{}}>
                <FeaturedPackage
                  featured={state.featured}
                  handleFeaturedpackage={handleFeaturedpackage}
                />
              </div>
              <div
                className="section-dropdown"
                style={{
                  position: "relative",
                  cursor: "pointer",
                  width: "auto",
                }}
              >
                <div>
                  <Button
                    style={{ paddingRight: "32px" }}
                    aria-controls="section-menu"
                    aria-haspopup="true"
                    onClick={handleClickSelect}
                  >
                    {dropdownValue?.name || "Select section"}
                    <img
                      alt="arrow"
                      src={ArrowDown}
                      style={{
                        position: "absolute",
                        right: "12px",
                        top: "12px",
                      }}
                    />
                  </Button>
                  <Menu
                    id="section-menu"
                    anchorEl={select}
                    keepMounted
                    open={Boolean(select)}
                    onClose={() => setSelect(null)}
                  >
                    {selectedMenuDropdown &&
                      selectedMenuDropdown.map((item) => {
                        return (
                          <MenuItem
                            onClick={() => handleCloseSelect(item)}
                            key={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Menu>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-primary btn-save"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          {isExperienceAddSecnameErrorValidation && (
            <p style={{ textAlign: "end", marginRight: "9%", color: "red" }}>
              {SECTION_NAME}
            </p>
          )}
          {isFeaturedErrorValidation && (
            <p style={{ textAlign: "end", marginRight: "9%", color: "red" }}>
              {isFeaturedErrorValidation}
            </p>
          )}
          <PackTypeButtons state={state} setState={setState} label={true} fromAdd={true}/>
          <div>
            <div className="form-group form-custom">
              <Input
                label=" Listing Title *"
                value={state?.pack_title}
                handleChange={(val) => handleChange("pack_title", val)}
              />
              {isExperienceAddPackTitleEmptyErrorValidation ? <Error error={PACKAGE_TITLE_EMPTY_ERROR_VALIDATION} /> : ""}
              {isExperienceAddPackTitleLengthErrorValidation ? <Error error={PACKAGE_TITLE_LENGTH_ERROR_VALIDATION} /> : ""}
              {symbolValidation?<Error error={symbolErrorMsg}/>:""}
            </div>
            {/* <Meta_Input
               length={state?.meta_title != null ? state?.meta_title.trim().length : 0}
                label="Meta Title*"
                maxlength={150}
                value={state?.meta_title}
                handleChange={(val) => meta_title_change("meta_title", val)}
                
              />
               <Meta_Input
               length={state?.meta_description != null ? state?.meta_description.trim().length : 0}
                label="Meta Description*"
                maxlength={250}
                value={state?.meta_description}
                handleChange={(val) => meta_description("meta_description", val)}
                
              /> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{flex: 0.5}}>
                <div className="common-images-box" onClick={() => setImageSelect(true)}>
                  <img
                    alt=""
                    src={imageThubnail ? imageThubnail.image : DefaultImage}
                  />
                  <h6>
                    <span><FaFileUpload /></span>
                    {addImage.length > 0 ? addImage.length : 0} images / videos
                  </h6>
                  {isExperienceAddImgErrorValidation ? <Error error={IMAGE} /> : ""}
                  {isExperienceAddImgThumbnailErrorValidation ? <Error error={THUMBNAIL_IMAGE_ERROR} /> : ""}
                </div> 

                <div className="form-group form-custom">
                
                <div className="package-price" id="price">
                  <div className="form-group form-custom">
                    <h6>Rs</h6>
                    <Input
                      label="Original Rate*"
                      value={state?.price}
                      handleChange={(value) => priceHandler("price", value)}
                      isDivider={true}
                      disableChar={true}
                    />
                    {isExperienceAddPointsEmptyErrorValidation ? <Error error={PRICE} /> : ""}
                    {isExperienceAddPointsLengthErrorValidation ? <Error error={PRICE_LENGTH} /> : ""}
                  </div>
                </div>
                <div className="package-price" id="price">
                    <div className="form-group form-custom" style={{
        display: 'flex',
        background: 'white',
        justifyContent: 'space-between',
        width: '89%',
        height: '41px',
        marginLeft: '14px',
        marginBottom: '0px',
      }}>
                      <p style={{opacity:"0.5"}}>Add Discounted Rate *</p> 
                      <img src={PlusIcon} alt="addIcon" style={{cursor:"pointer"}}  onClick={handleAddRate}/>
                      </div>
                  </div>
                <div className="package-price">
                  <div className="form-group form-custom">
                    <h6>Rs</h6>
                    <Input
                      label="Discounted Rate"
                      value={revisePrice}
                      disabled={true}
                      // handleChange={(value) => priceHandler("revised_price", value)}
                      isDivider={true}
                      
                    />
                    {isError.error && isError.name == "revised_price" ? <Error error={isError.msg} /> : ""}
                  </div>
                  </div>
                </div>
                <div
                  style={{ height: "164px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{alignItems:"flex-end"}}>
                  <label for="" style={{ marginLeft:"17px"}}>Note</label>
                  <p style={{ marginLeft:"17px", fontSize:"12px", fontWeight:"400", lineHeight:"14px", opacity:"0.4",color:"#000000"}}>{state?.note != null ? state?.note.trim().length : 0}/100</p>
                  </div>
                  <textarea
                   style={{ height: "98px",resize: "none" }}
                    maxlength="100"
                    value={
                      state?.note
                    }
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      noteHandler("note", value)
                    }
                  />
                </div>
                <div
                  style={{ height: "164px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{ alignItems: "flex-end" }}>
                    <label for="" style={{ marginLeft: "17px" }}>Meta Title</label>
                    <p style={{ marginLeft: "17px", fontSize: "12px", fontWeight: "400", lineHeight: "14px", opacity: "0.4", color: "#000000" }}>{state?.title != null ? state?.title.trim().length : 0}/80</p>
                  </div>
                  <textarea
                    style={{ height: "98px",resize: "none" }}
                    resize= "none"
                    maxlength="80"

                    value={state?.title}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                    noteHandler("meta_title", value)
                     }
                  />
                </div>
                <div
                  style={{ height: "164px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{ alignItems: "flex-end" }}>
                    <label for="" style={{ marginLeft: "17px" }}>Meta Description</label>
                    <p style={{ marginLeft: "17px", fontSize: "12px", fontWeight: "400", lineHeight: "14px", opacity: "0.4", color: "#000000" }}>{state?.meta_description != null ? state?.meta_description.trim().length : 0}/180</p>
                  </div>
                  <textarea
                    style={{ height: "98px",resize: "none" }}
                    resize= "none"
                    maxlength="180"

                    value={state.meta_description}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      noteHandler("meta_description", value)
                       }
                  />
                </div>
                {/* <div
                  style={{ height: "100px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{ alignItems: "flex-end" }}>
                    <label for="" style={{ marginLeft: "17px" }}>Product Code</label>
                    <p style={{ marginLeft: "17px", fontSize: "12px", fontWeight: "400", lineHeight: "14px", opacity: "0.4", color: "#000000" }}>{state?.edenred_product_code != null ? state?.edenred_product_code.trim().length : 0}/30</p>
                  </div>
                  <textarea
                    style={{ height: "40px",resize: "none" }}
                    resize= "none"
                    maxlength="30"
                    value={state.edenred_product_code}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                     noteHandler("edenred_product_code", value)
                    }
                  />
                </div> */}
                <div style={{ flex: 0.5, height: "fit-content", paddingBottom: "20px" }} className="form-group form-custom" id="brief">
                  <label for="">Brief Description *</label>
                  <textarea
                    value={state?.brief_description}
                    type="text"
                    style={{ height: "145px" }}
                    className="form-control"
                    onChange={(value) =>
                      briefDescriptionHandler("brief_description", value)
                    }
                  />
                  {isexperienceAddBrigDescEmtyErrorValidation ? (
                    <p style={{ color: "red" }}>{BRIEF_DESCRIPTION_EMPTY}</p>
                  ) : isexperienceAddBrigDescLengthErrorValidation ? (
                    <p style={{ color: "red" }}>
                      {BRIEF_DESCRIPTION_EMPTY_LENGTH}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                {state.package_type == "staybased" && 
                (<div className="form-group form-custom" id="groupSize">
                  <label for="">Guests No’s</label>
                  <MinMaxInput 
                    state={state} 
                    disableChar={true} 
                    label="Guests No."
                    placeholder1="Min"
                    placeholder2="Max"
                    setState={setState}
                    setIsError={setIsError}
                  />
                  {isError.error && isError.name == "guest_no" ? <Error error={isError.msg} /> : ""}
                </div>)}
                <div className="toggle-container">
                    <label for="">Highlighting</label>
                    <div className="featured-amenities">
                      <div className="box">
                        <FormControl component="fieldset">
                          <FormControlLabel
                            value="start"
                            control={
                              <PurpleSwitch
                                checked={state && state?.best_seller}
                                size="small"
                                color="primary"
                                name="best_seller"
                                onChange={handleBsetSeller}
                              />
                            }
                            label="Bestseller?"
                            labelPlacement="start"
                          />
                        </FormControl>
                      </div>
                      <div className="box">
                        <FormControl component="fieldset">
                          <FormControlLabel
                            value="start"
                            control={
                              <PurpleSwitch
                                checked={state && state?.covid_safe}
                                size="small"
                                color="#2196F3"
                                name="covid_safe"
                                onChange={covidSafeHandle}
                              />
                            }
                            label="COVID Safe"
                            labelPlacement="start"
                          />
                        </FormControl>
                      </div>
                      </div>
                    </div>
                {state.package_type == "staybased" &&
                (<div className="toggle-container">
                  <ToggleGroup
                    setState={setState}
                    state={state}
                    options={state.hotel_resort_facilities}
                    label="Hotel/ Resort Facilities"
                    optionsKey="hotel_resort_facilities"
                  />
                </div>)}
              </div>
              <div style={{flex: 1}}>
                <div
                  className="form-group form-custom"
                  style={{ flex: 1, marginLeft: 10 }}
                >
                  <label for="">
                    {isGiftVoucher ? "Redeem at" : "Location *"}
                  </label>
                  <div className="location-field">
                    <textarea
                      className="form-control"
                      rows="3"
                      cols="40"
                      type="text"
                      value={state?.location[0]?.address}
                      disabled
                    />
                    <div className="pin-icon">
                      <img
                        className="cursor-pointer"
                        src={PinLocation}
                        alt=""
                        onClick={() => setLocationSelect(true)}
                      />
                    </div>
                  </div>
                  {isexperienceAddLocationEmptyErrorvalidation ? (
                    <p style={{ color: "red" }}>{LOCATION_EMPTY}</p>
                  ) : isexperienceAddLocationLengthErrorvalidation ? (
                    <p style={{ color: "red" }}>{LOCATION_EMPTY_LENGTH}</p>
                  ) : (
                    ""
                  )}
                  {locationSelect && (
                    <GoogleMapModal
                      edit={isEdit}
                      open={locationSelect}
                      toggleModal={setLocationSelect}
                      handleCloseDone={() => setLocationSelect(false)}
                      updateLocation={setLocation}
                    />
                  )}
                </div>
                <SecondLocation currentPackage={state} setCurrentPackage={setState}/>
                {isGiftVoucher ? (
                  <div
                    style={{ flex: 0.6, marginLeft: 10 }}
                    className="form-group form-custom"
                    id="validTill"
                  >
                    <div>
                      <label for="">Valid till</label>
                      <input
                        value={state?.valid_till}
                        type="date"
                        min={new Date().toISOString().split("T")[0]}
                        className="form-control"
                        onChange={(value) =>
                          validTillHandler("valid_till", value)
                        }
                      />
                      {isexperienceAddValidTillErrorValidation && (
                        <p style={{ color: "red" }}>{VALID_TILL}</p>
                      )}
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <label for="">Highlighting</label>
                      <div>
                        <FormControl component="fieldset">
                          <FormControlLabel
                            value="start"
                            control={
                              <PurpleSwitch
                                checked={state && state?.best_seller}
                                size="small"
                                color="primary"
                                name="best_seller"
                                onChange={handleBsetSeller}
                              />
                            }
                            label="Bestseller?"
                            labelPlacement="start"
                          />
                        </FormControl>
                      </div>
                      <div>
                        <FormControl component="fieldset">
                          <FormControlLabel
                            value="start"
                            control={
                              <PurpleSwitch
                                checked={state && state.covid_safe}
                                size="small"
                                color="#2196F3"
                                name="covid_safe"
                                onChange={covidSafeHandle}
                              />
                            }
                            label="COVID Safe"
                            labelPlacement="start"
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{ flex: 0.6, marginLeft: 10, position: "relative" }}
                  >
                    <div
                      style={{ flex: "0.5 1 0%", position: "relative" }}
                      className="form-group form-custom w-100"
                      id="locationDropdown"
                      // className="form-group form-custom"
                    >
                      <label for=""> City Of Location *</label>
                      <Autocomplete
                        options={getLocationdropDownList}
                        city={state?.location_city}
                        locationCityval={state?.location_city}
                        postLocationCityData={postLocationCityData}
                      />
                      {isError.error && isError.name == "location_error" ? <Error error={isError.msg} /> : ""}
                    </div>
                    {state.package_type == "staybased" &&
                      (<div className="form-group form-custom" id="locationType">
                        <Input
                          label="Location Type*"
                          value={state?.location_type}
                          handleChange={(val) => handleChange("location_type", val)}
                        />
                        {isError.error && isError.name == "location_type" ? <Error error={isError.msg} /> : ""}
                      </div>)}
                      {state.package_type == "staybased" &&
                  (<div
                      className="form-group form-custom"
                      style={{ position: "relative" }}
                      id="schedule"
                    >
                      <div
                        className="d-flex"
                        style={{
                          background: "#fff",
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          padding: ".375rem 0",
                          lineHeight: "1.5",
                          fontWeight: "400",
                          color: "#495057",
                          fontSize: "1rem",
                          marginBottom: 10,
                        }}
                      >
                        <div className="col">Flexible dates</div>
                        <div className="col" style={{ textAlign: "right" }}>
                          <PurpleSwitch
                            checked={state && state.flexi_schedule}
                            size="small"
                            color="#2196F3"
                            name="flexi_schedule"
                            onChange={handleFlexiSchedule}
                          />
                        </div>
                      </div>
                      <label for="">Schedule *</label>
                      <input
                        disabled={state.flexi_schedule}
                        // value={!currentPackage.flexi_schedule?(isEmptyResultsArrayBool ? "" : scheduledData):''}
                        value={!state.flexi_schedule ? scheduledData : ""}
                        type="text"
                        className="form-control"
                      />
                      {isexperienceAddScheduleErrorValidation && (
                        <p style={{ color: "red" }}>{SCHEDULE}</p>
                      )}
                      <img
                        src={Edit}
                        style={{ position: "absolute", right: "20px", top: "105px", padding: "0px 10px", background: "#fff" }}
                        onClick={() =>
                          !state.flexi_schedule && setPopupSelect(true)
                        }
                      />

                    </div>)}
                                    {/* blocked_dates */}
                  
                                    <div
                    style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                    className="form-group form-custom" id="schedule"
                  >
                    <div className="d-flex" style={{ background: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.375rem 0', lineHeight: '1.5', fontWeight: '400', color: '#495057', fontSize: '1rem', marginBottom: 10 }}>
                      <div className="col-md-9" >Next Day Booking</div>
                      <div className="col" style={{ textAlign: 'right' }}>
                        <PurpleSwitch
                         size="small"
                         checked={state?.disable_tomorrows_bookings}
                          color="#2196F3"
                          name="flexi_schedule"
                          onChange={(event)=>handleBlockTommorow(event)}

                        />
                     
                      </div>
                    </div>
                   
                    <label for="">Block Date Range </label>
                    <input
                      value={block_date_range ? block_date_range:""}
                      disabled={state?.restricted_booking_dates}
                      type="text"
                      className="form-control"
                      id="schedule"
                    />
                  
                    <img
                      src={Edit}
                      style={{ position: "absolute", right: "20px", top: "105px", padding: "0px 10px", }}
                      onClick={() => { setPopupDateBLock(true) }}
                    />
                  </div>

                  {popupDateBlock && (
                  <BlockDatePopup
                    openPopup={popupDateBlock}
                    closeModal={setPopupDateBLock}
                    // updatePopupData={updateDateDataBlockDates}
                    saveDateData={saveDateDataBlockDates}
                    isHolidayListing={true}
                    data={state}
                    popupDateBlock={popupDateBlock}
                    
                  />
                )}
                  {/* blocked_dates */}

                      {popupSelect && (
                        <SchedulePopUp
                          openPopup={popupSelect}
                          closeModal={setPopupSelect}
                          updatePopupData={setSchedules}
                          updateRecurringData={setRecurring}
                          updateScheduledTimeData={setSpecificDate}
                          updateDateRangeData={setDateRanges}
                          isSpecificDisabled={true}
                          isRecurringDisabled={true}
                        />
                      )}
                  </div>
                )}
                <div
                  style={{ flex: 0.5,marginLeft: 10, position: "relative" }}
                  className="form-group form-custom"
                >
                  <div
                    className="d-flex"
                    style={{
                      background: "#fff",
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      padding: ".375rem 0",
                      lineHeight: "1.5",
                      fontWeight: "400",
                      color: "#495057",
                      fontSize: "1rem",
                    }}
                  >
                    <div className="col-md-9">Enable Payment</div>
                    <div className="col" style={{ textAlign: "right" }}>
                      <PurpleSwitch
                        checked={state.enable_payment}
                        size="small"
                        color="#2196F3"
                        name="enable_payment"
                        onChange={handleEnablePayment}
                      />
                    </div>
                  </div>
                </div>
                {!isGiftVoucher && (
                  <>
                    <div
                      style={{ flex: 0.6, marginLeft: 10 }}
                      className="form-group form-custom"
                      id="validTill"
                    >
                      <div>
                        <label for="">Valid till *</label>
                        <input
                          value={state?.valid_till}
                          type="date"
                          min={new Date().toISOString().split("T")[0]}
                          className="form-control"
                          onChange={(value) =>
                            validTillHandler("valid_till", value)
                          }
                          disabled={state?.schedule_type == "date_range"}
                        />
                        {isexperienceAddValidTillErrorValidation && (
                          <p style={{ color: "red" }}>{VALID_TILL}</p>
                        )}
                      </div>
                    </div>
                    
                  </>
                )}
                {state.package_type == "staybased" && 
                  (<div className="toggle-container" style={{marginLeft: "10px"}} id="amenities">
                    <ToggleGroup
                      setState={setState}
                      state={state}
                      options={state.featured_amenities}
                      label="Featured Amenities *"
                      optionsKey="featured_amenities"
                      setIsError={setIsError}
                    />
                    {isError.error && isError.name == "featured_amenities" ? <Error error={isError.msg} /> : ""}
                  </div>)}
                  {state.package_type == "staybased" &&
                  (<div className="toggle-container" style={{marginLeft: "10px"}}>
                    <ToggleGroup
                      setState={setState}
                      state={state}
                      options={state.room_amenities}
                      label="Room Amenities"
                      optionsKey="room_amenities"
                    />
                  </div>)}
                  {state.package_type == "itinerary" && 
                (<div className="form-group form-custom" style={{marginLeft: "10px"}} id="groupSize">
                  <label for="">Group Size*</label>
                  <MinMaxInput 
                    state={state} 
                    disableChar={true} 
                    label="Group Size"
                    placeholder1="Min"
                    placeholder2="Max"
                    setState={setState}
                    setIsError={setIsError}
                  />
                  {isError.error && isError.name == "guest_no" ? <Error error={isError.msg} /> : ""}
                </div>)}
                  {state.package_type == "itinerary" &&
                  (<div className="form-group form-custom" style={{marginLeft: "10px"}} id="itineraryFields">
                    <label>Activity Type*</label>
                    <Dropdown 
                      options={ACTIVITY_OPTIONS}
                      setState={setState}
                      state={state}
                      label="activity_type"
                      value={state?.activity_type}
                      setIsError={setIsError}
                    />
                    {isError.error && isError.name == "activity_type" ? <Error error={isError.msg} /> : ""}
                    <label className="mt-3">Activity Level*</label>
                    <Dropdown 
                      options={ACTIVITY_LEVEL_OPTIONS}
                      setState={setState}
                      state={state}
                      label="activity_level"
                      value={ACTIVITY_LEVEL_VALUE(state?.activity_level)}
                      setIsError={setIsError}
                    />
                    {isError.error && isError.name == "activity_level" ? <Error error={isError.msg} /> : ""}
                    <div style={{marginTop: "10px"}}>
                      <Input
                        label="Ages*"
                        value={state?.ages}
                        // disableChar={true}
                        handleChange={(val) => handleChange("ages", val)}
                      />
                      {isError.error && isError.name == "ages" ? <Error error={isError.msg} /> : ""}
                    </div>
                  </div>)}
              </div>
            </div>
          </div>
          <div className="form-group form-custom mt-3" id="desc">
          <Tabs defaultActiveKey="1" >
            <TabPane tab = {`Description *`} key="1">
              <CkEditor 
                data={content}
                handleChange={(newContent) => {
                  setContent(newContent); 
                  setIsexperienceAddDesErrorValidation(false)
                }}
              />
            </TabPane>
            {state.package_type == "itinerary" && (<>
            <TabPane tab = {`Itinerary`} key="2">
            <CkEditor 
                  data={state.itinerary}
                  handleChange={(newContent) => setState({...state, itinerary: newContent})}
                />
            </TabPane>
            <TabPane  tab = {`Accomodations`} key="3">
                <CkEditor 
                  data={state.accomodations}
                  handleChange={(newContent) => setState({...state, accomodations: newContent})}
                />
            </TabPane>
            <TabPane tab = {`Essential Info`} key="4">
                <CkEditor 
                  data={state.essential_info}
                  handleChange={(newContent) => setState({...state, essential_info: newContent})}
                />
            </TabPane>
            <TabPane tab = {`Schedule *`} key="5">
              <ScheduleBoxItinerary 
                setPopupSelect={setPopupSelect} 
                state={state}
                isEdit={true}
                />
            </TabPane>
            <TabPane tab = {`FAQ’s`} key="6">
                <CkEditor 
                  data={state.faq}
                  handleChange={(newContent) => setState({...state, faq: newContent})}
                />
            </TabPane>
            </>
            )}
          </Tabs>
          {isError.error && isError.name == "itinerary" ? <Error error={isError.msg} /> : ""}
          {isError.error && isError.name == "accomodations" ? <Error error={isError.msg} /> : ""}
          {isError.error && isError.name == "essential_info" ? <Error error={isError.msg} /> : ""}
          {isexperienceAddScheduleErrorValidation && state.package_type == "itinerary" && <p style={{ color: "red" }}>{SCHEDULE}</p>}
          {isexperienceAddDesErrorValidation && <p style={{ color: "red" }}>{DESCRIPTION}</p>}
          {isError.error && isError.name == "faq" ? <Error error={isError.msg} /> : ""}
          </div>
        </form>
      </div>

      
      {imageSelect && (
        <ImageMultiSelect
          title={state?.pack_title}
          images={addImage || []}
          open={imageSelect}
          toggleModal={setImageSelect}
          handleCloseDone={handleCloseImage}
          handleUpdatePackageImage={handleUpdatePackageImage}
          isAddNewEvent={true}
          isUpdateEvent={false}
          moduleType="experience"
        />
      )}

{addHandleRate&&<AddHandleRate 
                 open={addHandleRate}
                 modalClose={() => setAddHandleRate(false)}
                 setPriceList={setPriceList}
                 handleAddPrice={handleAddPrice}
                 priceList={priceList}
                 isAdd={true}
            />}
    </div>
  );
}
